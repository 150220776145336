import { Youtube, MessageSquare, ExternalLink, Settings, Gift } from 'lucide-react';
import type { Subscriber } from '../../types';

interface ProfileHeaderProps {
  subscriber: Subscriber;
}

export function ProfileHeader({ subscriber }: ProfileHeaderProps) {
  const getTierColor = (tier: string) => {
    switch (tier) {
      case 'vip': return 'from-purple-500 to-pink-500';
      case 'premium': return 'from-blue-500 to-cyan-500';
      default: return 'from-gray-500 to-gray-600';
    }
  };

  return (
    <div className="flex flex-col sm:flex-row items-start sm:items-center gap-4 mb-6">
      <div className="flex items-center gap-4 flex-1 w-full">
        <img
          src={subscriber.avatar}
          alt={`${subscriber.name}'s profile`}
          className="h-14 w-14 rounded-full border-2 border-purple-100 shadow-md hover:scale-105 transition-transform duration-200"
        />
        <div>
          <h3 className="font-semibold text-gray-900 text-lg">{subscriber.name}</h3>
          <div className="flex items-center gap-2 mt-1">
            <Youtube className="h-4 w-4 text-red-500" />
            <span className="text-sm text-gray-500">@{subscriber.username}</span>
          </div>
        </div>
      </div>
      <div className="flex flex-col sm:items-end gap-2 w-full sm:w-auto">
        <div className="flex items-center gap-2">
          <span className={`px-3 py-1 text-xs font-medium text-white rounded-full bg-gradient-to-r ${getTierColor(subscriber.subscriptionTier)} shadow-sm flex items-center gap-1`}>
            <Gift className="h-3 w-3" />
            <span>{subscriber.subscriptionTier} Supportr</span>
          </span>
        </div>
        <div className="grid grid-cols-3 sm:flex sm:flex-nowrap gap-2 w-full sm:w-auto">
          <button className="w-full sm:w-auto px-3 py-1.5 text-xs font-medium text-gray-600 hover:text-gray-900 bg-gray-100 hover:bg-gray-200 rounded-lg transition-colors duration-200 flex items-center justify-center gap-1">
            <MessageSquare className="h-3 w-3" />
            Message
          </button>
          <button className="w-full sm:w-auto px-3 py-1.5 text-xs font-medium text-gray-600 hover:text-gray-900 bg-gray-100 hover:bg-gray-200 rounded-lg transition-colors duration-200 flex items-center justify-center gap-1">
            <ExternalLink className="h-3 w-3" />
            Export
          </button>
          <button className="w-full sm:w-auto px-3 py-1.5 text-xs font-medium text-purple-600 hover:text-purple-700 bg-purple-100 hover:bg-purple-200 rounded-lg transition-colors duration-200 flex items-center justify-center gap-1">
            <Settings className="h-3 w-3" />
            Manage
          </button>
        </div>
      </div>
    </div>
  );
}