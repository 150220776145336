import { ShoppingBag, ExternalLink, Copy, Sparkles, TrendingUp, BarChart3, DollarSign, Percent } from 'lucide-react';
import type { Offer } from '../../types';
import { offers } from '../../data/mockData';

export function CreatorOffers() {
  const totalRevenue = offers.reduce((sum, offer) => sum + offer.stats.revenue, 0);
  const avgConversion = offers.reduce((sum, offer) => sum + offer.stats.conversion, 0) / offers.length;
  const totalUses = offers.reduce((sum, offer) => sum + offer.stats.uses, 0);

  return (
    <div className="bg-gray-900 border-t border-gray-800">
      {/* Main Container */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
        {/* Header Section */}
        <div className="text-center">
          <h2 className="text-3xl font-extrabold text-white">
            Track Your Success
          </h2>
          <p className="mt-4 text-gray-400 text-lg">
            Measure performance and optimize your partnerships with detailed analytics
          </p>
        </div>
      </div>

      {/* Offers and Analytics Section */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Section Header */}
        <div className="flex items-center justify-between mb-8">
          <div>
            <h2 className="text-2xl font-bold text-white">Creator Offers</h2>
            <p className="text-gray-400 mt-1">Manage your brand partnerships and track performance</p>
          </div>
          <button className="bg-purple-600 text-white px-4 py-2 rounded-lg hover:bg-purple-700 transition-colors duration-200 flex items-center gap-2">
            <ShoppingBag className="h-4 w-4" />
            Add New Brand Offer
          </button>
        </div>

        {/* Stats Overview */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
          {/* Total Revenue */}
          <div className="bg-gradient-to-br from-purple-900/20 to-pink-900/20 rounded-xl p-6 border border-purple-800/30">
            <div className="flex items-center gap-3 mb-4">
              <div className="p-2 bg-purple-900/30 rounded-lg">
                <DollarSign className="h-6 w-6 text-purple-600" />
              </div>
              <div>
                <p className="text-sm text-gray-400">Total Revenue</p>
                <p className="text-2xl font-bold text-white">${totalRevenue.toLocaleString()}</p>
              </div>
            </div>
            <div className="flex items-center gap-2 text-sm text-green-600">
              <TrendingUp className="h-4 w-4" />
              <span>12.5% from last month</span>
            </div>
          </div>

          {/* Average Conversion */}
          <div className="bg-gradient-to-br from-blue-900/20 to-cyan-900/20 rounded-xl p-6 border border-blue-800/30">
            <div className="flex items-center gap-3 mb-4">
              <div className="p-2 bg-blue-900/30 rounded-lg">
                <Percent className="h-6 w-6 text-blue-600" />
              </div>
              <div>
                <p className="text-sm text-gray-400">Avg. Conversion</p>
                <p className="text-2xl font-bold text-white">{avgConversion.toFixed(1)}%</p>
              </div>
            </div>
            <div className="flex items-center gap-2 text-sm text-green-600">
              <TrendingUp className="h-4 w-4" />
              <span>2.3% from last month</span>
            </div>
          </div>

          {/* Total Uses */}
          <div className="bg-gradient-to-br from-green-900/20 to-emerald-900/20 rounded-xl p-6 border border-green-800/30">
            <div className="flex items-center gap-3 mb-4">
              <div className="p-2 bg-green-900/30 rounded-lg">
                <BarChart3 className="h-6 w-6 text-green-600" />
              </div>
              <div>
                <p className="text-sm text-gray-400">Total Uses</p>
                <p className="text-2xl font-bold text-white">{totalUses}</p>
              </div>
            </div>
            <div className="flex items-center gap-2 text-sm text-green-600">
              <TrendingUp className="h-4 w-4" />
              <span>8.1% from last month</span>
            </div>
          </div>
        </div>

        {/* Featured Offers */}
        <div className="mb-12">
          <h3 className="text-lg font-semibold text-white mb-4">Featured Offers</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {offers.filter(offer => offer.featured).map((offer) => (
              <div
                key={offer.id}
                className="bg-gray-800 rounded-xl border border-gray-700 hover:border-purple-500 transition-all duration-200 overflow-hidden"
              >
                <div className="p-6">
                  {/* Offer Header */}
                  <div className="flex items-start justify-between mb-4">
                    <div className="flex items-center gap-3">
                      <img
                        src={offer.brandLogo}
                        alt={offer.brand}
                        className="h-12 w-12 rounded-lg object-cover"
                      />
                      <div>
                        <h4 className="font-semibold text-white">{offer.brand}</h4>
                        <p className="text-sm text-gray-400">{offer.category}</p>
                      </div>
                    </div>
                    <span className="px-3 py-1 bg-purple-100 text-purple-700 rounded-full text-sm font-medium">
                      {offer.discount}
                    </span>
                  </div>

                  {/* Offer Details */}
                  <h3 className="text-xl font-bold text-white mb-2">{offer.title}</h3>
                  <p className="text-gray-300 mb-4">{offer.description}</p>

                  {/* Promo Code and Visit Button */}
                  <div className="flex items-center gap-4 mb-6">
                    <div className="flex-1 bg-gray-900/50 rounded-lg p-3">
                      <p className="text-sm text-gray-400 mb-1">Promo Code</p>
                      <div className="flex items-center justify-between">
                        <code className="text-sm font-mono font-medium text-purple-600">{offer.code}</code>
                        <button className="text-gray-400 hover:text-gray-600">
                          <Copy className="h-4 w-4" />
                        </button>
                      </div>
                    </div>
                    <a
                      href={offer.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center gap-2 px-4 py-3 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors duration-200"
                    >
                      <ExternalLink className="h-4 w-4" />
                      <span>Visit</span>
                    </a>
                  </div>

                  {/* Offer Statistics */}
                  <div className="grid grid-cols-3 gap-4 bg-gray-900/30 p-3 rounded-lg">
                    <div className="bg-gray-800/50 p-3 rounded-lg">
                      <p className="text-sm text-gray-400 mb-1">Uses</p>
                      <p className="text-lg font-semibold text-white">{offer.stats.uses}</p>
                    </div>
                    <div className="bg-gray-800/50 p-3 rounded-lg">
                      <p className="text-sm text-gray-400 mb-1">Revenue</p>
                      <p className="text-lg font-semibold text-white">${offer.stats.revenue.toLocaleString()}</p>
                    </div>
                    <div className="bg-gray-800/50 p-3 rounded-lg">
                      <p className="text-sm text-gray-400 mb-1">Conversion</p>
                      <p className="text-lg font-semibold text-white">{offer.stats.conversion}%</p>
                    </div>
                  </div>
                </div>

                {/* Offer Footer */}
                <div className="px-6 py-4 bg-gray-900/30 border-t border-gray-700">
                  <div className="flex items-center justify-between text-sm">
                    <p className="text-gray-400">
                      Expires: <span className="text-white font-medium">{offer.expiresAt}</span>
                    </p>
                    <div className="flex items-center gap-1 text-purple-600">
                      <Sparkles className="h-4 w-4" />
                      <span className="font-medium">Featured Offer</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* All Active Offers */}
        <div>
          <h3 className="text-lg font-semibold text-white mb-4">All Active Offers</h3>
          <div className="grid grid-cols-1 gap-4">
            {offers.filter(offer => !offer.featured).map((offer) => (
              <div
                key={offer.id}
                className="bg-gray-800 rounded-lg border border-gray-700 p-4 hover:border-purple-500 transition-all duration-200"
              >
                <div className="flex items-center gap-4">
                  <img
                    src={offer.brandLogo}
                    alt={offer.brand}
                    className="h-12 w-12 rounded-lg object-cover"
                  />
                  <div className="flex-1">
                    <div className="flex items-center justify-between mb-1">
                      <h4 className="font-semibold text-white">{offer.title}</h4>
                      <span className="px-3 py-1 bg-purple-100 text-purple-700 rounded-full text-sm font-medium">
                        {offer.discount}
                      </span>
                    </div>
                    <p className="text-sm text-gray-400">{offer.description}</p>
                  </div>
                  <div className="flex items-center gap-3">
                    <div className="text-right">
                      <p className="text-sm text-gray-400">Uses</p>
                      <p className="font-semibold text-white">{offer.stats.uses}</p>
                    </div>
                    <button className="p-2 text-gray-400 hover:text-gray-600 rounded-lg hover:bg-gray-100">
                      <ExternalLink className="h-5 w-5" />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
