import { Clock, Youtube, Gift } from 'lucide-react';
import { ProfileHeader } from './ProfileHeader';
import { BasicInfo } from './BasicInfo';
import { GoogleOAuthData } from './GoogleOAuthData';
import { YouTubeData } from './YouTubeData';
import { AIAnalysis } from './AIAnalysis';
import { SubscribrBenefits } from './SubscribrBenefits';
import type { Subscriber } from '../../types';

interface CRMProfileCardProps {
  subscriber: Subscriber;
}

export function CRMProfileCard({ subscriber }: CRMProfileCardProps) {
  return (
    <div className="bg-white rounded-xl shadow-lg border border-gray-200 overflow-hidden hover:shadow-xl transition-all duration-300 w-full">
      <div className="p-6">
        <ProfileHeader subscriber={subscriber} />
        <BasicInfo subscriber={subscriber} />

        <div className="space-y-4">
          <GoogleOAuthData />
          <YouTubeData subscriber={subscriber} />
          <AIAnalysis subscriber={subscriber} />
          <SubscribrBenefits subscriber={subscriber} />

          <div className="mt-4 pt-4 border-t border-gray-200">
            <div className="flex flex-col gap-2 w-full">
              <p className="text-xs text-gray-400">Data Sources:</p>
              <div className="flex flex-wrap items-center gap-4 text-xs text-gray-500">
                <div className="flex items-center gap-1">
                  <div className="w-3 h-3">
                    <svg viewBox="0 0 24 24">
                      <path
                        d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                        fill="#4285F4"
                      />
                      <path
                        d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                        fill="#34A853"
                      />
                      <path
                        d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                        fill="#FBBC05"
                      />
                      <path
                        d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                        fill="#EA4335"
                      />
                    </svg>
                  </div>
                  <span>Google Identity</span>
                </div>
                <div className="flex items-center gap-1">
                  <Youtube className="h-3 w-3" />
                  <span>YouTube API</span>
                </div>
                <div className="flex items-center gap-1">
                  <Gift className="h-3 w-3" />
                  <span>subscribr Platform</span>
                </div>
              </div>
              <div className="flex items-center gap-1 text-xs text-gray-400">
                <Clock className="h-3 w-3" />
                <span>Updated 2 hours ago</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}