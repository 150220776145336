import { ShoppingBag, Copy, Tag, Clock, Sparkles, Search, Filter, ArrowUpRight } from 'lucide-react';
import type { Offer } from '../../types';

interface SubscribrOffersProps {
  offers: Offer[];
  creatorName?: string;
}

export function SubscribrOffers({ offers, creatorName = "Heidi" }: SubscribrOffersProps) {
  return (
    <div className="bg-gray-900 border-t border-gray-800">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
        <div className="max-w-4xl mx-auto">
          {/* Header Section */}
          <div className="max-w-3xl mx-auto text-center mb-12 bg-gradient-to-br from-gray-800 to-gray-900 p-8 rounded-xl border border-gray-700 shadow-xl">
            <div className="relative">
              <div className="absolute inset-0 bg-gradient-to-r from-purple-500/10 to-pink-500/10 rounded-xl blur-xl"></div>
              <div className="relative">
                <h2 className="text-3xl font-extrabold bg-gradient-to-r from-purple-400 to-pink-400 text-transparent bg-clip-text">
                  Your Brand Deals, All in One Place
                </h2>
                <p className="mt-4 text-gray-400 text-lg">
                  Create a beautiful storefront for all your partnerships, promo codes, and affiliate links. Give your community a single destination to support you and save money.
                </p>
              </div>
            </div>
          </div>

          {/* Offers Container */}
          <div className="bg-gradient-to-br from-gray-800/50 to-gray-900/50 rounded-2xl border border-gray-700/50 shadow-2xl backdrop-blur-sm overflow-hidden">
            {/* Header with Search and Filter */}
            <div className="border-b border-gray-700/50 p-6">
              <div className="flex flex-col md:flex-row md:items-center justify-between gap-4">
                {/* Store Information */}
                <div>
                  <h2 className="text-2xl font-bold text-white">Heidi's Store</h2>
                  <p className="text-gray-400 mt-1">Exclusive deals from {creatorName}'s favorite brands</p>
                </div>
                {/* Search and Filter */}
                <div className="flex items-center gap-4">
                  <div className="relative flex-1 md:flex-initial">
                    <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-400" />
                    <input
                      type="text"
                      placeholder="Search deals..."
                      className="w-full md:w-auto pl-10 pr-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-gray-200 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                    />
                  </div>
                  <button className="flex items-center gap-2 px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-gray-200 hover:bg-gray-700 transition-colors">
                    <Filter className="h-4 w-4" />
                    <span>Filter</span>
                  </button>
                </div>
              </div>

              {/* Categories */}
              <div className="flex items-center gap-3 mt-6 overflow-x-auto">
                {['All Deals', 'Travel Gear', 'Photography', 'Accessories', 'Clothing', 'Tech'].map((category) => (
                  <button
                    key={category}
                    className={`px-4 py-2 rounded-full text-sm font-medium whitespace-nowrap ${
                      category === 'All Deals'
                        ? 'bg-purple-600 text-white'
                        : 'bg-gray-800 text-gray-300 hover:bg-gray-700'
                    }`}
                  >
                    {category}
                  </button>
                ))}
              </div>
            </div>

            {/* Featured Deals */}
            <div className="p-6 grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
              {offers.filter(offer => offer.featured).map((offer) => (
                <div
                  key={offer.id}
                  className="group bg-gradient-to-br from-gray-800 to-gray-900 rounded-xl border border-gray-700 hover:border-purple-500 transition-all duration-200 overflow-hidden"
                >
                  <div className="relative aspect-[16/9] overflow-hidden">
                    <img
                      src={offer.brandLogo}
                      alt={offer.brand}
                      className="absolute inset-0 w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-gray-900 to-transparent"></div>
                    <div className="absolute top-4 left-4">
                      <span className="px-3 py-1 bg-purple-600 text-white rounded-full text-sm font-medium flex items-center gap-1">
                        <Sparkles className="h-3.5 w-3.5" />
                        Featured
                      </span>
                    </div>
                    <div className="absolute top-4 right-4">
                      <span className="px-3 py-1 bg-green-600 text-white rounded-full text-sm font-medium">
                        {offer.discount}
                      </span>
                    </div>
                  </div>

                  <div className="p-6">
                    <div className="flex items-center justify-between mb-3">
                      <h3 className="text-lg font-bold text-white">{offer.title}</h3>
                      <span className="text-sm text-gray-400">{offer.category}</span>
                    </div>
                    <p className="text-gray-300 mb-4">{offer.description}</p>

                    <div className="flex items-center gap-4">
                      <div className="flex-1 bg-gray-900/50 rounded-lg p-3">
                        <div className="flex items-center justify-between">
                          <code className="text-sm font-mono font-medium text-purple-400">{offer.code}</code>
                          <button className="text-gray-400 hover:text-gray-200 transition-colors">
                            <Copy className="h-4 w-4" />
                          </button>
                        </div>
                      </div>
                      <a
                        href={offer.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center gap-2 px-4 py-3 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors duration-200"
                      >
                        <span>Shop Now</span>
                        <ArrowUpRight className="h-4 w-4" />
                      </a>
                    </div>

                    <div className="mt-4 pt-4 border-t border-gray-700">
                      <div className="flex items-center justify-between text-sm">
                        <div className="flex items-center gap-2 text-gray-400">
                          <Clock className="h-4 w-4" />
                          <span>Expires {offer.expiresAt}</span>
                        </div>
                        <div className="flex items-center gap-2 text-gray-400">
                          <Tag className="h-4 w-4" />
                          <span>{offer.stats.uses} uses</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* Regular Deals Grid */}
            <div className="p-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
              {offers.filter(offer => !offer.featured).map((offer) => (
                <div
                  key={offer.id}
                  className="group bg-gray-800 rounded-lg border border-gray-700 hover:border-purple-500 transition-all duration-200 overflow-hidden"
                >
                  <div className="relative aspect-video overflow-hidden">
                    <img
                      src={offer.brandLogo}
                      alt={offer.brand}
                      className="absolute inset-0 w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-gray-900 to-transparent"></div>
                    <div className="absolute top-4 right-4">
                      <span className="px-3 py-1 bg-green-600 text-white rounded-full text-sm font-medium">
                        {offer.discount}
                      </span>
                    </div>
                  </div>

                  <div className="p-4">
                    <div className="flex items-center justify-between mb-2">
                      <h3 className="font-semibold text-white">{offer.title}</h3>
                      <span className="text-xs text-gray-400">{offer.category}</span>
                    </div>
                    <p className="text-sm text-gray-300 mb-4">{offer.description}</p>

                    <div className="flex items-center gap-3">
                      <div className="flex-1">
                        <code className="text-sm font-mono font-medium text-purple-400">{offer.code}</code>
                      </div>
                      <a
                        href={offer.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center gap-1 px-3 py-1.5 bg-gray-700 text-white text-sm rounded-lg hover:bg-gray-600 transition-colors"
                      >
                        <span>Shop</span>
                        <ArrowUpRight className="h-3.5 w-3.5" />
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
