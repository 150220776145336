import { useState, useEffect } from 'react';
import { Sparkles, Loader2, AlertCircle, Youtube } from 'lucide-react';
import { signInWithGoogle, checkRedirectResult, onAuthStateChange } from '../../lib/auth';
import { PageService } from '../../lib/pages';
import { getChannelDetails } from '../../lib/youtube';
import type { YouTubeChannelDetails } from '../../types/youtube';
import { auth } from '../../lib/firebase';

interface URLInputProps {
  onSubmit?: (url: string) => void;
}

export function URLInput({ onSubmit }: URLInputProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [pendingUrl, setPendingUrl] = useState<string | null>(null);
  const [showChannelInput, setShowChannelInput] = useState(false);
  const [channelDetails, setChannelDetails] = useState<YouTubeChannelDetails | null>(null);

  // Listen for auth state changes
  useEffect(() => {
    console.log('URLInput: Setting up auth state change listener.');
    const unsubscribe = onAuthStateChange((user) => {
      console.log('URLInput: Auth state changed.', { user });
      if (user) {
        const savedUrl = localStorage.getItem('pendingUrl');
        console.log('URLInput: Retrieved pendingUrl from localStorage:', { savedUrl });
        if (savedUrl) {
          setPendingUrl(savedUrl);
          setShowChannelInput(true);
          setSuccess(`URL "${savedUrl}" is available! Connect your YouTube channel to claim it.`);
          onSubmit?.(savedUrl);
          localStorage.removeItem('pendingUrl');
          console.log('URLInput: Cleared pendingUrl from localStorage.');
        }
      }
    });

    return () => {
      console.log('URLInput: Cleaning up auth state change listener.');
      unsubscribe();
    };
  }, [onSubmit]);

  // Check for redirect result on component mount
  useEffect(() => {
    console.log('URLInput: Checking for redirect result on mount.');
  
    let isMounted = true;
  
    checkRedirectResult()
      .then(user => {
        if (!isMounted) return; // Prevent state updates if unmounted
        console.log('URLInput: checkRedirectResult completed.', { user });
  
        const savedUrl = localStorage.getItem('pendingUrl');
        console.log('URLInput: Retrieved pendingUrl from localStorage after redirect:', { savedUrl });
  
        const urlToUse = (pendingUrl || savedUrl) ?? '';
        console.log('URLInput: Constructed urlToUse:', { urlToUse });
  
        if (user && urlToUse) {
          setPendingUrl(urlToUse);
          setShowChannelInput(true);
          setSuccess(`URL "${urlToUse}" is available! Connect your YouTube channel to claim it.`);
          onSubmit?.(urlToUse);
          localStorage.removeItem('pendingUrl');
          console.log('URLInput: Cleared pendingUrl from localStorage after processing redirect.');
        } else if (!urlToUse) {
          console.warn('URLInput: No URL available for processing.');
          setError('No URL was selected. Please try again.');
        }
      })
      .catch(error => {
        console.error('URLInput: Error checking redirect result:', error);
        setError('Failed to process redirect. Please try again.');
      });
  
    return () => {
      console.log('URLInput: Cleaning up redirect result check.');
      isMounted = false;
    };
  }, [pendingUrl, onSubmit]);
  

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log('URLInput: handleSubmit called.');
    setError(null);
    setSuccess(null);
    setShowChannelInput(false);
    setIsLoading(true);
  
    try {
      const form = e.target as HTMLFormElement;
      const input = form.elements.namedItem('url') as HTMLInputElement;
      const customUrl = input.value.trim();
      console.log('URLInput: User entered customUrl:', { customUrl });
  
      if (!customUrl) {
        console.warn('URLInput: No URL entered by user.');
        throw new Error('Please enter a URL for your page.');
      }
  
      // Validate URL format
      console.log('URLInput: Validating URL format.');
      if (!PageService.validateCustomUrl(customUrl)) {
        console.warn('URLInput: Invalid URL format entered.', { customUrl });
        throw new Error(
          'Invalid URL format. Use 3-30 characters, letters, numbers, hyphens, or underscores only.'
        );
      }
  
      // Check availability
      console.log('URLInput: Checking URL availability for:', customUrl);
      const isAvailable = await PageService.isUrlAvailable(customUrl);
      console.log('URLInput: URL availability result:', { isAvailable });
  
      if (isAvailable) {
        setSuccess(`The URL "subscribr.xyz/${customUrl}" is available!`);
        console.log(`URLInput: URL "${customUrl}" is available.`);
      } else {
        setError(`The URL "subscribr.xyz/${customUrl}" is already taken. Please choose another.`);
        console.warn(`URLInput: URL "${customUrl}" is not available.`);
        return; // Stop further processing if URL is unavailable
      }
  
      // Authenticate with Google
      console.log('URLInput: Initiating Google sign-in.');
      const user = await signInWithGoogle();
      console.log('URLInput: signInWithGoogle result:', { user });
  
      if (!user) {
        console.log('URLInput: User is not authenticated. Handling redirect or popup block.');
        localStorage.setItem('pendingUrl', customUrl);
        setSuccess('Popup was blocked. Redirecting to Google sign-in...');
        return;
      }      
  
      // User successfully authenticated with popup
      localStorage.setItem('pendingUrl', customUrl);
      console.log('URLInput: Saved pendingUrl to localStorage after successful sign-in:', {
        customUrl,
      });
      setShowChannelInput(true);
      setSuccess(`URL "${customUrl}" is available! Connect your YouTube channel to claim it.`);
      onSubmit?.(customUrl);
    } catch (error) {
      console.error('URLInput: handleSubmit encountered an error:', error);
      setError(error instanceof Error ? error.message : 'An unexpected error occurred');
    } finally {
      setIsLoading(false);
      console.log('URLInput: handleSubmit completed. setIsLoading(false).');
    }
  };
  
    

  const handleChannelSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log('URLInput: handleChannelSubmit called.');
  
    const urlToUse = (pendingUrl || localStorage.getItem('pendingUrl')) ?? '';
    console.log('URLInput: handleChannelSubmit using URL:', { urlToUse });
  
    if (!urlToUse) {
      console.warn('URLInput: No URL selected before channel submission.');
      setError('Please select a URL first');
      return;
    }
  
    setIsLoading(true);
    setError(null);
  
    try {
      const form = e.target as HTMLFormElement;
      const input = form.elements.namedItem('channelUrl') as HTMLInputElement;
      let channelId = input.value.trim();
      console.log('URLInput: User entered channelId:', { channelId });
  
      // Add @ if it's a handle without it
      if (!channelId.startsWith('@') && !channelId.startsWith('UC')) {
        channelId = `@${channelId}`;
        console.log('URLInput: Modified channelId to include @:', { channelId });
      }
  
      const details = await getChannelDetails(channelId);
      console.log('URLInput: Retrieved channel details:', { details });
  
      const user = auth.currentUser;
      console.log('URLInput: Current authenticated user:', { user });
  
      if (!user) {
        console.warn('URLInput: No authenticated user found.');
        throw new Error('Please sign in to continue');
      }
  
      await PageService.reservePage(urlToUse, details.id, details, user.uid);
      console.log('URLInput: Page successfully reserved:', { urlToUse, details, userId: user.uid });
  
      setSuccess('Page successfully reserved! Redirecting to your new page...');
      console.log('URLInput: setSuccess called with success message.');
  
      // Redirect to the new page after a short delay
      setTimeout(() => {
        console.log('URLInput: Redirecting to new page:', `/${urlToUse}`);
        window.location.href = `/${urlToUse}`;
      }, 2000);
  
    } catch (error) {
      console.error('URLInput: handleChannelSubmit encountered an error:', error);
      setError(error instanceof Error ? error.message : 'Failed to connect channel');
    } finally {
      setIsLoading(false);
      console.log('URLInput: handleChannelSubmit completed. setIsLoading(false).');
    }
  };
  

  return (
    <div className="space-y-4">
      <form onSubmit={handleSubmit} className="flex flex-col sm:flex-row items-stretch sm:items-center gap-2">
        <div className="relative flex-1">
          <div className="absolute inset-y-0 left-1 flex items-center pointer-events-none text-gray-400">
            <span className="whitespace-nowrap">subscribr.xyz/</span>
          </div>
          <input
            type="text"
            name="url"
            placeholder="yourname"
            className={`
              w-full pl-[105px] pr-4 py-3 bg-gray-800 border 
              ${error ? 'border-red-500' : 'border-gray-700'} 
              rounded-lg text-white placeholder-gray-500 
              focus:outline-none focus:ring-2 
              ${error ? 'focus:ring-red-500' : 'focus:ring-purple-500'} 
              focus:border-transparent min-h-[48px]
            `}
            disabled={isLoading}
          />
          {error && (
            <div className="absolute inset-y-0 right-3 flex items-center">
              <AlertCircle className="h-5 w-5 text-red-500" aria-hidden="true" />
            </div>
          )}
        </div>
        <button 
          type="submit"
          disabled={isLoading}
          className={`
            flex items-center justify-center gap-2 px-6 py-3 
            bg-gradient-to-r from-purple-600 to-pink-600 
            rounded-lg font-medium transition-all duration-200 
            min-h-[48px] disabled:opacity-50 
            ${!isLoading && 'hover:opacity-90 hover:scale-105'}
          `}
        >
          {isLoading ? (
            <>
              <Loader2 className="h-4 w-4 animate-spin" />
              <span>Checking...</span>
            </>
          ) : (
            <>
              <Sparkles className="h-4 w-4" />
              <span>Create Page</span>
            </>
          )}
        </button>
      </form>

      {/* Error Message */}
      {error && (
        <div className="flex items-center gap-2 bg-red-900/20 border border-red-800/30 text-red-400 px-4 py-3 rounded-lg">
          <AlertCircle className="h-5 w-5 shrink-0" />
          <p className="text-sm">{error}</p>
        </div>
      )}

      {/* Channel Input Form */}
      {showChannelInput && (
        <form onSubmit={handleChannelSubmit} className="mt-4">
          <div className="relative">
            <div className="absolute inset-y-0 left-3 flex items-center pointer-events-none">
              <Youtube className="h-5 w-5 text-red-500" />
            </div>
            <input
              type="text"
              name="channelUrl"
              placeholder="Enter channel URL or @handle"
              className="w-full pl-11 pr-4 py-3 bg-gray-800 border border-gray-700 rounded-lg text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
              disabled={isLoading}
            />
          </div>
          <button
            type="submit"
            disabled={isLoading}
            className="w-full mt-2 flex items-center justify-center gap-2 px-4 py-3 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors duration-200 disabled:opacity-50"
          >
            {isLoading ? (
              <>
                <Loader2 className="h-4 w-4 animate-spin" />
                <span>Connecting...</span>
              </>
            ) : (
              <>
                <Youtube className="h-4 w-4" />
                <span>Connect YouTube Channel</span>
              </>
            )}
          </button>
        </form>
      )}

      {/* Success Message */}
      {success && (
        <div className="flex items-center gap-2 bg-green-900/20 border border-green-800/30 text-green-400 px-4 py-3 rounded-lg">
          <Sparkles className="h-5 w-5 shrink-0" />
          <p className="text-sm">{success}</p>
        </div>
      )}
    </div>
  );
}
