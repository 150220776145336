import { Sparkles } from 'lucide-react';
import type { Subscriber } from '../../types';

interface AIAnalysisProps {
  subscriber: Subscriber;
}

export function AIAnalysis({ subscriber }: AIAnalysisProps) {
  return (
    <div className="bg-gradient-to-br from-indigo-50 via-purple-50 to-pink-50 rounded-lg p-4 shadow-sm border border-indigo-100/50 backdrop-blur-sm">
      <div className="flex items-center gap-2 mb-4">
        <div className="flex items-center gap-2">
          <Sparkles className="h-4 w-4 text-indigo-500" />
          <h4 className="text-sm font-medium text-gray-900">Analysis by Gemini AI</h4>
        </div>
        <span className="text-xs bg-gradient-to-r from-indigo-500 to-purple-500 text-white px-3 py-1 rounded-full ml-auto shadow-sm">
          Multi-Source Analysis
        </span>
      </div>
      <div className="grid grid-cols-1 gap-4">
        <div className="bg-white/80 p-4 rounded-lg border border-indigo-200/50 hover:border-indigo-300/50 transition-colors duration-200 shadow-sm backdrop-blur-sm">
          <h5 className="text-sm font-medium text-indigo-900 mb-2">Engagement Summary</h5>
          <p className="text-sm text-gray-600">
            {subscriber.name} is a highly engaged {subscriber.subscriptionTier} member who joined on {subscriber.joinDate}. 
            With {subscriber.youtubeStats.subscribrStats.communityEngagement.postsCount} community posts and {subscriber.youtubeStats.subscribrStats.communityEngagement.repliesCount} replies, 
            they're among your most active community members. Their content relevance score of {subscriber.youtubeStats.subscribrStats.smartBenefits.contentScore}% indicates strong alignment with your content.
          </p>
        </div>

        <div className="bg-white/80 p-4 rounded-lg border border-purple-200/50 hover:border-purple-300/50 transition-colors duration-200 shadow-sm backdrop-blur-sm">
          <h5 className="text-sm font-medium text-indigo-900 mb-2">Content & Purchase Behavior</h5>
          <p className="text-sm text-gray-600">
            Based on YouTube data, they follow {subscriber.youtubeStats.subscriptions.similar} creators in your niche, 
            with a strong focus on {subscriber.youtubeStats.subscriptions.categories[0].name.toLowerCase()} content 
            ({Math.round((subscriber.youtubeStats.subscriptions.categories[0].count / subscriber.youtubeStats.subscriptions.total) * 100)}% of subscriptions). 
            They've saved ${subscriber.youtubeStats.subscribrStats.offers.totalSaved} through {subscriber.youtubeStats.subscribrStats.offers.usedDeals} creator deals, 
            showing high purchase intent.
          </p>
        </div>

        <div className="bg-gradient-to-br from-white/90 to-white/80 p-4 rounded-lg border border-pink-200/50 hover:border-pink-300/50 transition-colors duration-200 shadow-sm backdrop-blur-sm">
          <h5 className="text-sm font-medium text-indigo-900 mb-2">Recommendations</h5>
          <ul className="space-y-2 text-sm text-gray-600">
            <li className="flex items-center gap-2">
              <span className="h-1.5 w-1.5 rounded-full bg-gradient-to-r from-indigo-500 to-purple-500" />
              Consider featuring their {subscriber.youtubeStats.subscribrStats.communityEngagement.topThreads} top-performing community threads
            </li>
            <li className="flex items-center gap-2">
              <span className="h-1.5 w-1.5 rounded-full bg-gradient-to-r from-purple-500 to-pink-500" />
              Target with premium travel gear offers (based on playlist analysis)
            </li>
            <li className="flex items-center gap-2">
              <span className="h-1.5 w-1.5 rounded-full bg-gradient-to-r from-pink-500 to-rose-500" />
              Potential brand ambassador ({subscriber.engagementRate}% engagement rate)
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}