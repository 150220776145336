import { Gift, MessageCircle, Zap } from 'lucide-react';
import type { Subscriber } from '../../types';

interface SubscribrBenefitsProps {
  subscriber: Subscriber;
}

export function SubscribrBenefits({ subscriber }: SubscribrBenefitsProps) {
  return (
    <div className="bg-gradient-to-br from-purple-50 to-pink-50 rounded-lg p-4 shadow-sm">
      <div className="flex flex-col sm:flex-row items-start sm:items-center gap-2 mb-4">
        <Gift className="h-5 w-5 text-purple-500" />
        <h4 className="text-sm font-medium text-gray-900">subscribr Benefits & Engagement</h4>
      </div>
      <div className="space-y-3">
        {/* Community Access */}
        <div className="bg-white p-4 rounded-lg border border-gray-100">
          <div className="flex flex-col sm:flex-row items-start sm:items-center gap-3 mb-3">
            <div className="p-2 bg-green-50 rounded-lg shrink-0">
              <MessageCircle className="h-4 w-4 text-green-500" />
            </div>
            <div className="flex-1">
              <p className="text-sm font-medium text-gray-900">Community Access</p>
              <p className="text-xs text-gray-500">Direct engagement with Heidi and fellow travelers</p>
            </div>
            <span className="text-xs bg-green-100 text-green-700 px-2 py-1 rounded-full mt-2 sm:mt-0">Active Member</span>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 mt-2">
            <div className="bg-gray-50 p-2 rounded-lg">
              <p className="text-xs text-gray-500">Community Posts</p>
              <p className="text-sm font-medium text-gray-900">{subscriber.youtubeStats.subscribrStats.communityEngagement.postsCount}</p>
            </div>
            <div className="bg-gray-50 p-2 rounded-lg">
              <p className="text-xs text-gray-500">Replies</p>
              <p className="text-sm font-medium text-gray-900">{subscriber.youtubeStats.subscribrStats.communityEngagement.repliesCount}</p>
            </div>
            <div className="bg-gray-50 p-2 rounded-lg">
              <p className="text-xs text-gray-500">Top Threads</p>
              <p className="text-sm font-medium text-gray-900">{subscriber.youtubeStats.subscribrStats.communityEngagement.topThreads}</p>
            </div>
            <div className="bg-gray-50 p-2 rounded-lg">
              <p className="text-xs text-gray-500">Last Active</p>
              <p className="text-sm font-medium text-gray-900">{subscriber.youtubeStats.subscribrStats.communityEngagement.lastActive}</p>
            </div>
          </div>
        </div>
        
        {/* Exclusive Offers */}
        <div className="bg-white p-4 rounded-lg border border-gray-100">
          <div className="flex flex-col sm:flex-row items-start sm:items-center gap-3 mb-3">
            <div className="p-2 bg-purple-50 rounded-lg shrink-0">
              <Gift className="h-4 w-4 text-purple-500" />
            </div>
            <div className="flex-1">
              <p className="text-sm font-medium text-gray-900">Exclusive Offers</p>
              <p className="text-xs text-gray-500">Creator-sponsored deals platform access</p>
            </div>
            <span className="text-xs bg-purple-100 text-purple-700 px-2 py-1 rounded-full mt-2 sm:mt-0">
              {subscriber.youtubeStats.subscribrStats.offers.activeDeals} Active Deals
            </span>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 mt-2">
            <div className="bg-gray-50 p-2 rounded-lg">
              <p className="text-xs text-gray-500">Total Saved</p>
              <p className="text-sm font-medium text-gray-900">${subscriber.youtubeStats.subscribrStats.offers.totalSaved}</p>
            </div>
            <div className="bg-gray-50 p-2 rounded-lg">
              <p className="text-xs text-gray-500">Used Deals</p>
              <p className="text-sm font-medium text-gray-900">{subscriber.youtubeStats.subscribrStats.offers.usedDeals}</p>
            </div>
            <div className="col-span-2 bg-gray-50 p-2 rounded-lg">
              <p className="text-xs text-gray-500">Last Purchase</p>
              <p className="text-sm font-medium text-gray-900">{subscriber.youtubeStats.subscribrStats.offers.lastPurchase}</p>
            </div>
          </div>
        </div>
        
        {/* Smart Benefits */}
        <div className="bg-white p-4 rounded-lg border border-gray-100">
          <div className="flex flex-col sm:flex-row items-start sm:items-center gap-3 mb-3">
            <div className="p-2 bg-blue-50 rounded-lg shrink-0">
              <Zap className="h-4 w-4 text-blue-500" />
            </div>
            <div className="flex-1">
              <p className="text-sm font-medium text-gray-900">Smart Benefits</p>
              <p className="text-xs text-gray-500">Personalized content and auto-applied discounts</p>
            </div>
            <span className="text-xs bg-blue-100 text-blue-700 px-2 py-1 rounded-full mt-2 sm:mt-0">Enabled</span>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 mt-2">
            <div className="bg-gray-50 p-2 rounded-lg">
              <p className="text-xs text-gray-500">Auto-applied Discounts</p>
              <p className="text-sm font-medium text-gray-900">{subscriber.youtubeStats.subscribrStats.smartBenefits.appliedDiscounts}</p>
            </div>
            <div className="bg-gray-50 p-2 rounded-lg">
              <p className="text-xs text-gray-500">Personalized Content</p>
              <p className="text-sm font-medium text-gray-900">{subscriber.youtubeStats.subscribrStats.smartBenefits.personalizedContent}</p>
            </div>
            <div className="col-span-2">
              <div className="flex items-center justify-between mb-1">
                <p className="text-xs text-gray-500">Content Relevance Score</p>
                <p className="text-xs font-medium text-blue-600">{subscriber.youtubeStats.subscribrStats.smartBenefits.contentScore}%</p>
              </div>
              <div className="h-2 bg-gray-200 rounded-full overflow-hidden">
                <div 
                  className="h-full bg-gradient-to-r from-blue-500 to-cyan-500"
                  style={{ width: `${subscriber.youtubeStats.subscribrStats.smartBenefits.contentScore}%` }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}