import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { CreatorLanding } from './components/creator/CreatorLanding';
import { ServerStatus } from './components/debug/ServerStatus';

function App() {
  return (
    <BrowserRouter>
      <ServerStatus />
      <Routes>
        <Route path="/" element={<CreatorLanding />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
