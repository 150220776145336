// src/components/sections/HowItWorksSection.tsx

import { Users, Gift, Target } from 'lucide-react';
import { AuthSteps } from '../auth/AuthSteps';

export function HowItWorksSection() {
  return (
    <div className="bg-gray-900 border-t border-gray-800 py-12 sm:py-24">
      <div className="w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header Section */}
        <div className="text-center mb-12">
          <h2 className="text-2xl sm:text-3xl font-extrabold text-white">
            Simple Setup for Your Fans
          </h2>
          <p className="mt-4 max-w-3xl mx-auto text-base sm:text-lg text-gray-400">
            Your subscribers can join your community in seconds with our verified two-step process
          </p>
          <div className="mt-8 max-w-3xl mx-auto bg-gradient-to-br from-purple-900/20 to-pink-900/20 rounded-lg p-4 sm:p-6 border border-purple-800/30">
            <div className="flex flex-col sm:flex-row items-center gap-3 text-left">
              <div className="bg-purple-900/30 p-2 rounded-lg shrink-0">
                <Users className="h-5 w-5 text-purple-400" />
              </div>
              <div>
                <h3 className="text-sm font-medium text-purple-300 mb-1">Interactive Demo Flow</h3>
                <p className="text-sm text-gray-400">
                  Below is an interactive demonstration of how your subscribers will verify their YouTube subscription
                  and join your community. Try clicking through the steps to simulate the complete verification process.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* AuthSteps Container */}
        <div className="w-full max-w-3xl mx-auto rounded-lg sm:rounded-xl p-4 sm:p-6 md:p-8">
          <AuthSteps />
        </div>

        {/* Features Grid */}
        <div className="mt-12 grid grid-cols-1 gap-8 max-w-3xl mx-auto">
          {/* Feature 1 */}
          <div className="flex items-center gap-4 bg-gray-800/50 rounded-lg p-4 border border-gray-700">
            <div className="bg-purple-400/10 w-12 h-12 rounded-xl flex items-center justify-center shrink-0">
              <Users className="h-6 w-6 text-purple-400" />
            </div>
            <div>
              <h3 className="font-medium text-lg text-white mb-1">
                Verified Access
              </h3>
              <p className="text-gray-400 text-sm">
                Subscribers prove they follow you through YouTube verification
              </p>
            </div>
          </div>

          {/* Feature 2 */}
          <div className="flex items-center gap-4 bg-gray-800/50 rounded-lg p-4 border border-gray-700">
            <div className="bg-blue-400/10 w-12 h-12 rounded-xl flex items-center justify-center shrink-0">
              <Gift className="h-6 w-6 text-blue-400" />
            </div>
            <div>
              <h3 className="font-medium text-lg text-white mb-1">
                Exclusive Offers
              </h3>
              <p className="text-gray-400 text-sm">
                Members get access to special deals and community features
              </p>
            </div>
          </div>

          {/* Feature 3 */}
          <div className="flex items-center gap-4 bg-gray-800/50 rounded-lg p-4 border border-gray-700">
            <div className="bg-pink-400/10 w-12 h-12 rounded-xl flex items-center justify-center shrink-0">
              <Target className="h-6 w-6 text-pink-400" />
            </div>
            <div>
              <h3 className="font-medium text-lg text-white mb-1">
                Smart Analytics
              </h3>
              <p className="text-gray-400 text-sm">
                You get detailed insights about your community's interests
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
