import { Mail, Calendar, MapPin, BarChart, Activity } from 'lucide-react';
import type { Subscriber } from '../../types';

interface BasicInfoProps {
  subscriber: Subscriber;
}

export function BasicInfo({ subscriber }: BasicInfoProps) {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-6 w-full bg-gradient-to-br from-purple-50/10 via-pink-50/10 to-blue-50/10 p-4 sm:p-6 rounded-xl border border-purple-200/20 hover:border-purple-300/30 transition-all duration-300 backdrop-blur-sm shadow-lg">
      <div className="flex items-center gap-3 bg-gradient-to-br from-white/90 to-white/80 p-4 rounded-lg border border-purple-200/30 hover:border-purple-300/50 transition-all duration-300 shadow-sm hover:shadow-md group">
        <div className="p-2.5 bg-gradient-to-br from-purple-100 to-pink-100 rounded-lg shadow-sm group-hover:shadow-md transition-all duration-300">
          <Mail className="h-4 w-4 text-purple-500" />
        </div>
        <div>
          <p className="text-xs text-gray-500">Verified Email</p>
          <p className="text-sm font-medium bg-gradient-to-r from-purple-600 to-pink-600 bg-clip-text text-transparent truncate max-w-[200px] sm:max-w-[160px]">{subscriber.email}</p>
        </div>
      </div>
      <div className="flex items-center gap-3 bg-gradient-to-br from-white/90 to-white/80 p-4 rounded-lg border border-blue-200/30 hover:border-blue-300/50 transition-all duration-300 shadow-sm hover:shadow-md group">
        <div className="p-2.5 bg-gradient-to-br from-blue-100 to-cyan-100 rounded-lg shadow-sm group-hover:shadow-md transition-all duration-300">
          <Calendar className="h-4 w-4 text-blue-500" />
        </div>
        <div>
          <p className="text-xs text-gray-500">Your Supportr Since</p>
          <p className="text-sm font-medium bg-gradient-to-r from-blue-600 to-cyan-600 bg-clip-text text-transparent truncate max-w-[200px] sm:max-w-[160px]">{subscriber.joinDate}</p>
        </div>
      </div>
      <div className="flex items-center gap-3 bg-gradient-to-br from-white/90 to-white/80 p-4 rounded-lg border border-green-200/30 hover:border-green-300/50 transition-all duration-300 shadow-sm hover:shadow-md group">
        <div className="p-2.5 bg-gradient-to-br from-green-100 to-emerald-100 rounded-lg shadow-sm group-hover:shadow-md transition-all duration-300">
          <MapPin className="h-4 w-4 text-green-500" />
        </div>
        <div>
          <p className="text-xs text-gray-500">Location</p>
          <p className="text-sm font-medium bg-gradient-to-r from-green-600 to-emerald-600 bg-clip-text text-transparent truncate max-w-[200px] sm:max-w-[160px]">{subscriber.location}</p>
        </div>
      </div>
      <div className="flex items-center gap-3 bg-gradient-to-br from-white/90 to-white/80 p-4 rounded-lg border border-orange-200/30 hover:border-orange-300/50 transition-all duration-300 shadow-sm hover:shadow-md group">
        <div className="p-2.5 bg-gradient-to-br from-orange-100 to-amber-100 rounded-lg shadow-sm group-hover:shadow-md transition-all duration-300">
          <BarChart className="h-4 w-4 text-orange-500" />
        </div>
        <div>
          <p className="text-xs text-gray-500">Engagement</p>
          <div className="flex items-center gap-1.5 truncate max-w-[200px] sm:max-w-[160px]">
            <p className="text-sm font-medium bg-gradient-to-r from-orange-600 to-amber-600 bg-clip-text text-transparent">{subscriber.engagementRate}%</p>
            <Activity className="h-3 w-3 text-green-500" />
          </div>
        </div>
      </div>
    </div>
  );
}