// Utility functions for markdown formatting

import { marked } from 'marked';

const renderer = new marked.Renderer();

renderer.text = (text: string) => {
  return text.replace(
    /@[\w-]+/g,
    match => `<span class="text-blue-400 bg-blue-900/20 px-1.5 py-0.5 rounded font-mono text-sm">${match}</span>`
  );
};

export function formatMarkdown(text: string): string {
  text = text.trim().replace(/\r\n/g, '\n');
  
  marked.setOptions({
    gfm: true,
    breaks: true,
    headerIds: false,
    mangle: false,
    renderer
  });
  
  return marked(text);
}

export function formatSummarySection(title: string, content: string[]): string {
  return `
## ${title}

${content.map(item => `* ${item}`).join('\n')}
`.trim();
}

export function formatVideoContext(context: {
  title?: string;
  publishedAt?: string;
  viewCount?: string;
  likeCount?: string;
  description?: string;
}): string {
  const date = context.publishedAt ? 
    new Date(context.publishedAt).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: '2-digit'
    }) : 'Unknown';

  return `
## YouTube Comment Analysis: "${context.title || 'Unknown Video'}"

This analysis examines the comments on a YouTube video titled "${context.title}", published on ${date}. The video has received ${context.viewCount || '0'} views and ${context.likeCount || '0'} likes.

${context.description ? `\n**Video Description:**\n${context.description}\n` : ''}
`.trim();
}