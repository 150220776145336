interface CacheEntry<T> {
  data: T;
  timestamp: number;
}

interface VideoCache {
  comments: { [videoId: string]: CacheEntry<YouTubeComment[]> };
  summaries: { [videoId: string]: CacheEntry<string> };
}

const CACHE_DURATION = 30 * 60 * 1000; // 30 minutes

class VideoDataCache {
  private static instance: VideoDataCache;
  private cache: { [channelId: string]: VideoCache } = {};

  private constructor() {}

  static getInstance(): VideoDataCache {
    if (!VideoDataCache.instance) {
      VideoDataCache.instance = new VideoDataCache();
    }
    return VideoDataCache.instance;
  }

  getComments(channelId: string, videoId: string): YouTubeComment[] | null {
    const entry = this.cache[channelId]?.comments[videoId];
    if (!entry) return null;
    
    if (Date.now() - entry.timestamp > CACHE_DURATION) {
      delete this.cache[channelId].comments[videoId];
      return null;
    }
    
    return entry.data;
  }

  getSummary(channelId: string, videoId: string): string | null {
    const entry = this.cache[channelId]?.summaries[videoId];
    if (!entry) return null;
    
    if (Date.now() - entry.timestamp > CACHE_DURATION) {
      delete this.cache[channelId].summaries[videoId];
      return null;
    }
    
    return entry.data;
  }

  setComments(channelId: string, videoId: string, comments: YouTubeComment[]): void {
    if (!this.cache[channelId]) {
      this.cache[channelId] = { comments: {}, summaries: {} };
    }
    
    this.cache[channelId].comments[videoId] = {
      data: comments,
      timestamp: Date.now()
    };
  }

  setSummary(channelId: string, videoId: string, summary: string): void {
    if (!this.cache[channelId]) {
      this.cache[channelId] = { comments: {}, summaries: {} };
    }
    
    this.cache[channelId].summaries[videoId] = {
      data: summary,
      timestamp: Date.now()
    };
  }

  clearChannel(channelId: string): void {
    delete this.cache[channelId];
  }
}

export const videoCache = VideoDataCache.getInstance();