import { useEffect, useState } from 'react';
import { checkServerStatus } from '../../lib/server';

export function ServerStatus() {
  const [status, setStatus] = useState({
    server: false,
    youtube: false,
    firebase: false,
    timestamp: ''
  });

  useEffect(() => {
    const verifyStatus = async () => {
      const result = await checkServerStatus();
      setStatus(result);
    };
    verifyStatus();
  }, []);

  return null; // Hidden component that just checks server status
}