import { type ReactNode } from 'react';
import { Users, Youtube, TrendingUp, ArrowUpRight, Sparkles } from 'lucide-react';
import { transcriptAnalysis } from '../../data/videoData';

const similarChannels = [
  {
    name: 'Adventure Alex',
    handle: '@adventurealexyt',
    avatar: 'https://images.unsplash.com/photo-1522529599102-193c0d76b5b6?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    subscribers: '245K',
    overlap: 78,
    categories: ['Travel', 'Photography'],
    engagement: 8.2,
    collaborationScore: 92
  },
  {
    name: 'Emily Explores',
    handle: '@emilyexplores',
    avatar: 'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    subscribers: '189K',
    overlap: 72,
    categories: ['Travel', 'Lifestyle'],
    engagement: 7.5,
    collaborationScore: 88
  },
  {
    name: 'Tech Traveler Mike',
    handle: '@techtravelermike',
    avatar: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    subscribers: '312K',
    overlap: 68,
    categories: ['Tech', 'Travel'],
    engagement: 6.9,
    collaborationScore: 85
  }
];

const audienceInsights = [
  {
    category: 'Content Preferences',
    insights: [
      'Travel vlogs are most watched (45%)',
      'Photography tutorials highly engaged (32%)',
      'Tech reviews drive purchases (28%)'
    ]
  },
  {
    category: 'Collaboration Potential',
    insights: [
      'Joint travel series opportunities',
      'Cross-promotion with gear reviews',
      'Shared brand campaign potential'
    ]
  }
];

export function SimilarChannelsSection() {
  return (
    <div className="bg-gradient-to-br from-gray-800 to-gray-900 rounded-xl p-8 border border-gray-700">
      <div className="flex items-center gap-3 mb-8">
        <div className="p-3 rounded-lg bg-gradient-to-br from-purple-600 to-pink-600">
          <Users className="h-5 w-5 text-white" />
        </div>
        <div>
          <h3 className="text-lg font-semibold text-white">Similar Channels</h3>
          <p className="text-sm text-gray-400">AI-identified collaboration opportunities</p>
        </div>
      </div>

      {/* Brand & Product Mentions */}
      <div className="mb-8 bg-gray-800/50 rounded-xl p-6 border border-gray-700">
        <div className="flex flex-col sm:flex-row items-start sm:items-center gap-3 justify-between mb-6">
          <div className="flex items-center gap-2">
            <Youtube className="h-4 w-4 text-red-500" />
            <h4 className="text-sm font-medium text-white">Recent Video Analysis</h4>
          </div>
          <span className="text-xs bg-gray-900/50 text-gray-400 px-2 py-1 rounded-full">
            2 days ago
          </span>
        </div>
        
        <div className="space-y-6">
          {transcriptAnalysis.recentVideos.map((video, index) => (
            <div key={index} className="bg-gray-900/50 rounded-lg p-4 border border-gray-700">
              <div className="flex flex-col md:flex-row items-start gap-4 mb-4">
                <div className="relative w-full md:w-48 h-48 md:h-28 rounded-lg overflow-hidden shrink-0">
                  <img
                    src={video.thumbnail}
                    alt={video.title}
                    className="absolute inset-0 w-full h-full object-cover hover:scale-105 transition-transform duration-200"
                  />
                </div>
                <div className="flex-1 w-full">
                  <div className="flex flex-col md:flex-row items-start md:items-center gap-3 mb-2">
                    <img
                      src={video.channel.avatar}
                      alt={video.channel.name}
                      className="w-8 h-8 rounded-full border border-gray-700"
                    />
                    <div className="flex-1">
                      <h5 className="text-sm font-medium text-white">{video.title}</h5>
                      <div className="flex flex-wrap items-center gap-2 text-xs text-gray-400">
                        <span>{video.channel.handle}</span>
                        <span>•</span>
                        <span>{video.views} views</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              {/* Sponsorship Analysis */}
              {video.sponsorships && video.sponsorships.length > 0 && (
                <div className="mb-4 bg-purple-900/20 rounded-lg p-3 border border-purple-800/30">
                  <div className="flex flex-wrap items-center gap-2 mb-3">
                    <p className="text-xs text-purple-400">Sponsorship Detection</p>
                    <span className="text-xs bg-purple-900/30 text-purple-400 px-2 py-0.5 rounded-full">
                      {video.sponsorships.length} Found
                    </span>
                  </div>
                  {video.sponsorships.map((sponsor, idx) => {
                    const transcript = video.transcripts?.[idx] || video.transcript || '';
                    return (
                      <div key={idx} className="mb-3 last:mb-0">
                        <div className="flex flex-col md:flex-row items-start md:items-center gap-2 justify-between mb-2">
                          <div className="flex flex-wrap items-center gap-2">
                            <span className="text-sm text-white">{sponsor.brand}</span>
                            {sponsor.brand === 'HubSpot' && (
                              <a
                                href="https://www.hubspot.com"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-xs bg-purple-900/30 text-purple-400 px-2 py-0.5 rounded-full hover:bg-purple-900/50 transition-colors"
                              >
                                Visit Site
                              </a>
                            )}
                            <a
                              href={sponsor.brand === 'Fundrise' ? 'https://fundrise.com/' : 'https://zbiotics.com/'}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-xs bg-purple-900/30 text-purple-400 px-2 py-0.5 rounded-full hover:bg-purple-900/50 transition-colors"
                            >
                              Visit Site
                            </a>
                            <span className="text-xs bg-purple-900/30 text-purple-400 px-2 py-0.5 rounded-full">
                              {sponsor.type}
                            </span>
                          </div>
                          <span className="text-xs text-green-400">{sponsor.sentiment}</span>
                        </div>
                        <div className="text-sm text-gray-400 bg-gray-900/50 rounded p-3 whitespace-pre-wrap">
                          {transcript.split(/(Support for|link|in the description)/i).map((part, i) => {
                            if (/Support for/i.test(part)) {
                              return <span key={i} className="bg-yellow-300/20 text-yellow-200 px-1 rounded" suppressHydrationWarning>Support for</span>;
                            } else if (/link/i.test(part)) {
                              return <span key={i} className="bg-blue-300/20 text-blue-200 px-1 rounded" suppressHydrationWarning>link</span>;
                            } else if (/in the description/i.test(part)) {
                              return <span key={i} className="bg-green-300/20 text-green-200 px-1 rounded" suppressHydrationWarning>in the description</span>;
                            }
                            return part;
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                <div>
                  <p className="text-xs text-purple-400 mb-2">Brands Mentioned</p>
                  <div className="flex flex-wrap gap-2">
                    {video.brands.map((brand) => (
                      <span
                        key={brand}
                        className="px-2 py-1 text-xs bg-purple-900/30 text-purple-400 rounded-full"
                      >
                        {brand}
                      </span>
                    ))}
                  </div>
                </div>
                
                <div>
                  <p className="text-xs text-blue-400 mb-2">Products Mentioned</p>
                  <div className="flex flex-wrap gap-2">
                    {video.products.map((product) => (
                      <span
                        key={product}
                        className="px-2 py-1 text-xs bg-blue-900/30 text-blue-400 rounded-full"
                      >
                        {product}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
              
              <div className="flex items-center gap-2 mt-4 pt-4 border-t border-gray-700">
                <Sparkles className="h-3 w-3 text-green-400" />
                <span className="text-xs text-green-400">{video.sentiment} Brand Sentiment</span>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 mb-8">
        {similarChannels.map((channel) => (
          <div
            key={channel.handle}
            className="bg-gray-800/50 rounded-xl border border-gray-700 overflow-hidden hover:border-purple-500 transition-all duration-200"
          >
            <div className="p-6">
              <div className="flex flex-col items-center text-center md:flex-row md:items-start md:text-left gap-4 mb-4">
                <img
                  src={channel.avatar}
                  alt={channel.name}
                  className="h-12 w-12 rounded-full border-2 border-purple-500"
                />
                <div className="flex-1">
                  <h4 className="font-medium text-white">{channel.name}</h4>
                  <div className="flex items-center gap-1 text-sm text-gray-400">
                    <Youtube className="h-3.5 w-3.5" />
                    <span>{channel.handle}</span>
                  </div>
                </div>
              </div>

              <div className="space-y-4">
                <div className="flex items-center justify-between text-sm">
                  <span className="text-gray-400">Subscribers</span>
                  <span className="text-white font-medium">{channel.subscribers}</span>
                </div>

                <div>
                  <div className="flex items-center justify-between text-sm mb-1">
                    <span className="text-gray-400">Audience Overlap</span>
                    <span className="text-white font-medium">{channel.overlap}%</span>
                  </div>
                  <div className="h-2 bg-gray-700 rounded-full overflow-hidden">
                    <div
                      className="h-full bg-gradient-to-r from-purple-500 to-pink-500"
                      style={{ width: `${channel.overlap}%` }}
                    />
                  </div>
                </div>

                <div className="flex flex-wrap gap-2">
                  {channel.categories.map((category) => (
                    <span
                      key={category}
                      className="px-2 py-1 text-xs bg-gray-700 text-gray-300 rounded-full"
                    >
                      {category}
                    </span>
                  ))}
                </div>

                <div className="pt-4 border-t border-gray-700">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      <Sparkles className="h-4 w-4 text-purple-400" />
                      <span className="text-sm text-purple-400">Collaboration Score</span>
                    </div>
                    <span className="text-sm font-medium text-white">{channel.collaborationScore}%</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="px-6 py-4 bg-gray-900/50 border-t border-gray-700">
              <button className="w-full flex items-center justify-center gap-2 px-4 py-2 bg-purple-600 hover:bg-purple-700 text-white rounded-lg transition-colors">
                <span className="text-sm">View Opportunities</span>
                <ArrowUpRight className="h-4 w-4" />
              </button>
            </div>
          </div>
        ))}
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {audienceInsights.map((section) => (
          <div
            key={section.category}
            className="bg-gray-800/50 rounded-lg p-6 border border-gray-700"
          >
            <h4 className="text-sm font-medium text-purple-400 mb-4">{section.category}</h4>
            <div className="space-y-4">
              {section.insights.map((insight, index) => (
                <div
                  key={index}
                  className="flex items-start gap-3 text-sm text-gray-300"
                >
                  <span className="h-1.5 w-1.5 rounded-full bg-purple-500 mt-1.5 shrink-0" />
                  {insight}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}