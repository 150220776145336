import { Youtube, Calendar, Users, Info, ThumbsUp, List, PlaySquare, Percent, Heart } from 'lucide-react';
import type { Subscriber } from '../../types';

// Format large numbers with K/M suffixes
const formatNumber = (num: number) => {
  if (num >= 1000000) {
    return `${(num / 1000000).toFixed(1)}M`;
  }
  if (num >= 1000) {
    return `${(num / 1000).toFixed(1)}K`;
  }
  return num.toString();
};

interface YouTubeDataProps {
  subscriber: Subscriber;
}

export function YouTubeData({ subscriber }: YouTubeDataProps) {
  return (
    <div className="bg-gradient-to-br from-gray-50 to-gray-100 rounded-lg p-4 shadow-sm space-y-4 w-full">
      <div className="flex flex-col sm:flex-row items-start sm:items-center gap-2 mb-4">
        <Youtube className="h-4 w-4 text-red-500" />
        <h4 className="text-sm font-medium text-gray-900">YouTube API Data</h4>
        <span className="text-xs bg-red-100 text-red-600 px-2 py-0.5 rounded-full sm:ml-auto">Read Only Access</span>
      </div>

      <div className="bg-gradient-to-br from-red-50/50 to-red-100/50 rounded-lg p-4 shadow-sm border border-red-200/50">
        {/* Basic Channel Data */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 mb-4">
          <div className="bg-gradient-to-br from-red-100 to-red-200 p-4 rounded-lg border border-red-300 shadow-sm">
            <div className="flex items-center gap-2 mb-1">
              <Youtube className="h-4 w-4 text-red-500" />
              <p className="text-xs text-gray-600">Channel Size</p>
            </div>
            <div className="mt-1">
              <p className="text-xl font-bold text-gray-900">{formatNumber(subscriber.youtubeStats.channelSubscribers)}</p>
              <p className="text-xs text-gray-500 mt-0.5">subscribers</p>
            </div>
          </div>
          <div className="bg-white p-4 rounded-lg border border-gray-200 shadow-sm">
            <div className="flex items-center gap-2 mb-1">
              <Calendar className="h-4 w-4 text-gray-400" />
              <p className="text-xs text-gray-600">Following Since</p>
            </div>
            <div className="mt-1">
              <p className="text-xl font-bold text-gray-900">{subscriber.youtubeStats.subscriptionDate}</p>
              <p className="text-xs text-gray-500 mt-0.5">first subscribed</p>
            </div>
          </div>
        </div>
        
        <div className="text-xs text-gray-500 flex items-center gap-1 mt-2">
          <Info className="h-3 w-3" />
          <span>Data available through YouTube Data API v3</span>
        </div>
      </div>

      {/* Public Activity Metrics */}
      <div className="grid grid-cols-2 sm:grid-cols-4 gap-3 bg-gradient-to-br from-gray-50 to-gray-100 rounded-lg p-4">
        <div className="bg-purple-50 p-3 rounded-lg">
          <ThumbsUp className="h-4 w-4 text-purple-500 mb-1" />
          <p className="text-xl font-semibold text-gray-900">{subscriber.youtubeStats.engagement.likedVideos}</p>
          <p className="text-xs text-gray-500">Liked Videos</p>
        </div>
        <div className="bg-green-50 p-3 rounded-lg">
          <List className="h-4 w-4 text-green-500 mb-1" />
          <p className="text-xl font-semibold text-gray-900">{subscriber.youtubeStats.engagement.playlists}</p>
          <p className="text-xs text-gray-500">Public Playlists</p>
        </div>
        <div className="bg-green-50 p-3 rounded-lg">
          <PlaySquare className="h-4 w-4 text-green-500 mb-1" />
          <p className="text-xl font-semibold text-gray-900">
            {subscriber.youtubeStats.engagement.playlistDetails.reduce((acc, pl) => acc + pl.videoCount, 0)}
          </p>
          <p className="text-xs text-gray-500">Videos in Playlists</p>
        </div>
        <div className="bg-orange-50 p-3 rounded-lg">
          <Users className="h-4 w-4 text-orange-500 mb-1" />
          <p className="text-xl font-semibold text-gray-900">{subscriber.youtubeStats.subscriptions.total}</p>
          <p className="text-xs text-gray-500">Subscribed Channels</p>
        </div>
      </div>
      
      {/* Public Playlists */}
      <div className="bg-gradient-to-br from-gray-50 to-gray-100 rounded-lg p-4">
        <div className="flex flex-col sm:flex-row items-start sm:items-center gap-2 mb-3">
          <List className="h-4 w-4 text-green-500" />
          <h4 className="text-sm font-medium text-gray-900">Public Playlists</h4>
          <span className="text-xs text-gray-500 sm:ml-auto">via playlistItems endpoint</span>
        </div>
        <div className="space-y-2">
          {subscriber.youtubeStats.engagement.playlistDetails
            .filter(pl => pl.visibility === 'public')
            .map((playlist) => (
              <div key={playlist.title} className="bg-white p-3 rounded-lg border border-gray-100">
                <div className="flex flex-col sm:flex-row items-start sm:items-center gap-2">
                  <div>
                    <p className="text-sm font-medium text-gray-900">{playlist.title}</p>
                    <p className="text-xs text-gray-500">{playlist.videoCount} videos</p>
                  </div>
                  <span className="text-xs bg-green-100 text-green-700 px-2 py-1 rounded-full sm:ml-auto">
                    {playlist.visibility}
                  </span>
                </div>
              </div>
            ))}
        </div>
      </div>

      {/* Content Preferences */}
      <div className="bg-gradient-to-br from-gray-50 to-gray-100 rounded-lg p-4">
        <div className="flex items-center gap-2 mb-3">
          <Percent className="h-4 w-4 text-indigo-500" />
          <h4 className="text-sm font-medium text-gray-900">Content Preferences</h4>
        </div>
        <div className="space-y-3">
          {subscriber.youtubeStats.contentPreferences.map((pref) => (
            <div key={pref.category} className="relative">
              <div className="flex items-center justify-between mb-1">
                <p className="text-sm text-gray-600">{pref.category}</p>
                <p className="text-sm font-medium text-gray-900">{pref.percentage}%</p>
              </div>
              <div className="h-2 bg-gray-200 rounded-full overflow-hidden">
                <div 
                  className="h-full bg-gradient-to-r from-purple-500 to-pink-500"
                  style={{ width: `${pref.percentage}%` }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Similar Channels */}
      <div className="bg-gradient-to-br from-gray-50 to-gray-100 rounded-lg p-4">
        <div className="flex items-center justify-between mb-3">
          <div className="flex items-center gap-2">
            <Youtube className="h-4 w-4 text-red-500" />
            <h4 className="text-sm font-medium text-gray-900">Channel Subscriptions</h4>
          </div>
          <span className="text-xs text-gray-500">via subscriptions endpoint</span>
        </div>
        <div className="space-y-3">
          {subscriber.youtubeStats.subscriptions.categories.map((category) => (
            <div key={category.name} className="relative">
              <div className="flex items-center justify-between mb-1">
                <div className="flex items-center gap-2">
                  <p className="text-sm text-gray-600">{category.name}</p>
                  <span className="text-xs text-gray-400 px-2 py-0.5 bg-gray-100 rounded-full">
                    {Math.round((category.count / subscriber.youtubeStats.subscriptions.total) * 100)}%
                  </span>
                </div>
                <p className="text-sm font-medium text-gray-900">{category.count} channels</p>
              </div>
              <div className="h-2 bg-gray-200 rounded-full overflow-hidden">
                <div 
                  className="h-full bg-gradient-to-r from-green-500 to-emerald-500"
                  style={{ width: `${(category.count / subscriber.youtubeStats.subscriptions.total) * 100}%` }}
                />
              </div>
            </div>
          ))}
        </div>
        <div className="mt-3 pt-3 border-t border-gray-200">
          <div className="flex items-center gap-2">
            <div className="p-1.5 bg-green-100 rounded">
              <Users className="h-3 w-3 text-green-600" />
            </div>
            <p className="text-xs text-gray-500">
              {subscriber.youtubeStats.subscriptions.similar} creators in similar categories as your channel
            </p>
          </div>
        </div>
      </div>

      {/* Interest Tags */}
      <div className="bg-gradient-to-br from-gray-50 to-gray-100 rounded-lg p-4">
        <div className="flex items-center gap-2 mb-3">
          <Heart className="h-4 w-4 text-pink-500" />
          <h4 className="text-sm font-medium text-gray-900">Interest Tags</h4>
        </div>
        <div className="flex flex-wrap gap-2">
          {subscriber.interests.map((interest) => (
            <span
              key={interest}
              className="px-3 py-1 text-xs font-medium bg-purple-50 text-purple-600 rounded-full hover:bg-purple-100 transition-colors duration-200 cursor-default"
            >
              {interest}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
}