import { MessageCircleHeart, ExternalLink, X, Menu } from 'lucide-react';
import { useState } from 'react';

export function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <header className="border-b border-gray-800">
      <nav className="relative z-50 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-16">
        <div className="flex items-center justify-between h-full">
          {/* Logo */}
          <div className="flex items-center gap-2">
            <div className="h-8 w-8 rounded-lg bg-gradient-to-br from-purple-600 to-pink-600 flex items-center justify-center">
              <MessageCircleHeart className="h-5 w-5 text-white" />
            </div>
            <span className="font-bold text-xl">Subscribr</span>
          </div>
          
          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-4">
            <a href="#" className="px-4 py-2 text-gray-400 hover:text-white hover:bg-gray-800 rounded-lg transition-all duration-200">
              For Creators
            </a>
            <a href="#" className="px-4 py-2 text-gray-400 hover:text-white hover:bg-gray-800 rounded-lg transition-all duration-200">
              For Fans
            </a>
            <a href="#" className="px-4 py-2 text-gray-400 hover:text-white hover:bg-gray-800 rounded-lg transition-all duration-200">
              For Brands
            </a>
            <a 
              href="#" 
              className="px-4 py-2 bg-gradient-to-r from-purple-600 to-pink-600 text-white rounded-lg hover:opacity-90 transition-all duration-200 flex items-center gap-2"
            >
              <span>Get Started</span>
              <ExternalLink className="h-4 w-4" />
            </a>
          </div>
          
          {/* Mobile menu button */}
          <button 
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="md:hidden p-2 rounded-lg hover:bg-gray-800 transition-colors"
          >
            {isMenuOpen ? (
              <X className="w-6 h-6 text-gray-400" />
            ) : (
              <Menu className="w-6 h-6 text-gray-400" />
            )}
          </button>
        </div>
        
        {/* Mobile Navigation */}
        <div className={`
          md:hidden fixed inset-x-0 top-16 bg-gray-900/95 backdrop-blur-sm border-b border-gray-800 z-40
          transform transition-all duration-300 ease-in-out
          ${isMenuOpen ? 'translate-y-0 opacity-100' : '-translate-y-full opacity-0 pointer-events-none'}
        `}>
          <div className="px-4 py-6 space-y-4 relative">
            <a href="#" className="block px-4 py-2 text-gray-400 hover:text-white hover:bg-gray-800 rounded-lg transition-all duration-200">
              For Creators
            </a>
            <a href="#" className="block px-4 py-2 text-gray-400 hover:text-white hover:bg-gray-800 rounded-lg transition-all duration-200">
              For Fans
            </a>
            <a href="#" className="block px-4 py-2 text-gray-400 hover:text-white hover:bg-gray-800 rounded-lg transition-all duration-200">
              For Brands
            </a>
            <a 
              href="#" 
              className="block px-4 py-2 bg-gradient-to-r from-purple-600 to-pink-600 text-white rounded-lg hover:opacity-90 transition-all duration-200"
            >
              Get Started
            </a>
          </div>
        </div>
      </nav>
    </header>
  );
}