// src/components/forms/ChannelInput.tsx

import { useState, useEffect } from 'react';
import { Youtube, Loader2, CheckCircle2, AlertCircle, ArrowLeft } from 'lucide-react';
import { getChannelDetails, ChannelNotFoundError, InvalidHandleError, YouTubeAPIError } from '../../lib/youtube';
import { ChannelPreview } from './ChannelPreview';
import type { YouTubeChannelDetails } from '../../types/youtube';

interface ChannelInputProps {
  onChannelFound?: (channelDetails: YouTubeChannelDetails) => void;
}

interface HistoryEntry {
  channel: YouTubeChannelDetails;
  fetchedAt: number; // Unix timestamp in milliseconds
  entryId: string; // Unique identifier for each history entry
}

const MAX_HISTORY_SIZE = 10; // Maximum number of history entries
const HISTORY_TIME_LIMIT = 10 * 60 * 1000; // 10 minutes in milliseconds

export function ChannelInput({ onChannelFound }: ChannelInputProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  
  // History state
  const [history, setHistory] = useState<HistoryEntry[]>([]);
  const [currentIndex, setCurrentIndex] = useState<number>(-1);

  // Current channelDetails derived from history
  const channelDetails = currentIndex >= 0 ? history[currentIndex].channel : null;

  const extractChannelId = (input: string): string => {
    try {
      input = input.trim().replace(/\/$/, '');

      if (input.includes('youtube.com/')) {
        const url = new URL(input);
        if (url.pathname.includes('/channel/')) {
          const channelId = url.pathname.split('/channel/')[1].split('/')[0];
          if (!channelId) throw new Error('Please enter a valid YouTube channel URL');
          return channelId;
        } else if (url.pathname.includes('/@')) {
          const handle = url.pathname.split('/@')[1].split('/')[0];
          if (!handle) throw new Error('Please enter a valid YouTube channel handle');
          return '@' + handle;
        } else if (url.pathname.includes('/user/')) {
          throw new Error('Legacy YouTube usernames are no longer supported. Please use the channel ID or @handle instead.');
        }
      }
      
      if (input.startsWith('@')) {
        const handle = input.substring(1);
        if (!handle) throw new Error('Please enter a channel handle after @');
        if (!/^[a-zA-Z0-9_-]{3,30}$/.test(handle)) {
          throw new Error('Channel handle can only contain letters, numbers, underscores, and hyphens');
        }
        return input;
      }
      
      if (input.startsWith('UC')) {
        if (!/^UC[a-zA-Z0-9_-]{22}$/.test(input)) {
          throw new Error('Invalid channel ID format');
        }
        return input;
      }
      
      if (/^[a-zA-Z0-9_-]{3,30}$/.test(input)) {
        return `@${input}`;
      }
      
      throw new Error('Please enter a valid YouTube channel URL, @handle, or channel ID');
    } catch (err) {
      if (err instanceof Error) {
        throw err;
      }
      throw new Error('Invalid channel format. Please check your input and try again.');
    }
  };

const handleAnalyze = async (channelId: string) => {
  try {
    setError(null);

    const details = await getChannelDetails(channelId);
    
    const currentTime = Date.now();
    const entryId = `${details.id}-${currentTime}`;

    const existingIndex = history.findIndex(entry => entry.channel.id === details.id);
    let updatedHistory: HistoryEntry[] = [...history];

    if (existingIndex !== -1) {
      updatedHistory.splice(existingIndex, 1);
    }

    updatedHistory.push({ channel: details, fetchedAt: currentTime, entryId });

    if (updatedHistory.length > MAX_HISTORY_SIZE) {
      updatedHistory = updatedHistory.slice(updatedHistory.length - MAX_HISTORY_SIZE);
    }

    setHistory(updatedHistory);
    setCurrentIndex(updatedHistory.length - 1);

    onChannelFound?.(details);
  } catch (err) {
    if (err instanceof InvalidHandleError || err instanceof ChannelNotFoundError) {
      setError(err.message);
    } else if (err instanceof YouTubeAPIError) {
      setError(err.message);
    } else {
      setError('Unable to analyze channel. Please try again.');
    }
  }
};


  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);

    const form = e.target as HTMLFormElement;
    const input = form.elements.namedItem('channelUrl') as HTMLInputElement;
    const inputValue = input.value.trim();
    
    if (!inputValue) {
      setError('Please enter a YouTube channel URL, @username, or channel ID');
      setIsLoading(false);
      return;
    }

    try {
      const channelId = extractChannelId(inputValue);
      await handleAnalyze(channelId);
    } catch (err) {
      // Handle errors thrown by extractChannelId
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError('An error occurred while processing your input.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleBack = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      setError(null);
    }
  };

  const handleForward = () => {
    if (currentIndex < history.length - 1) {
      setCurrentIndex(currentIndex + 1);
      setError(null);
    }
  };

  // Periodically clean up old history entries
  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = Date.now();
      setHistory(prevHistory => {
        // Keep entries that are within the time limit or are the current one
        return prevHistory.filter((entry, index) => {
          const timeDifference = currentTime - entry.fetchedAt;
          return timeDifference <= HISTORY_TIME_LIMIT || index <= currentIndex;
        });
      });
    }, 60 * 1000); // Run every 1 minute

    return () => clearInterval(interval);
  }, [currentIndex]);

  return (
    <div className="space-y-6">
      {/* Navigation Buttons */}
      <div className="flex items-center gap-2">
        {currentIndex > 0 && (
          <button
            type="button"
            onClick={handleBack}
            className="flex items-center gap-2 px-4 py-2 bg-gray-700 text-gray-200 rounded hover:bg-gray-600 transition-colors duration-200"
          >
            <ArrowLeft className="h-4 w-4" />
            <span>Back</span>
          </button>
        )}
        {currentIndex < history.length - 1 && (
          <button
            type="button"
            onClick={handleForward}
            className="flex items-center gap-2 px-4 py-2 bg-gray-700 text-gray-200 rounded hover:bg-gray-600 transition-colors duration-200"
          >
            <ArrowLeft className="h-4 w-4 transform rotate-180" />
            <span>Forward</span>
          </button>
        )}
      </div>

      {/* Search Form */}
      <form onSubmit={handleSubmit} className="flex flex-col sm:flex-row items-stretch sm:items-center gap-2">
        <div className="relative flex-1">
          <div className="absolute inset-y-0 left-3 flex items-center pointer-events-none">
            <Youtube className="h-5 w-5 text-red-500" />
          </div>
          <input
            type="text"
            name="channelUrl" 
            placeholder="Enter channel URL or @username (e.g., @channelname)"
            className={`w-full pl-11 pr-4 py-3 bg-gray-800 border ${
              error ? 'border-red-500' : 'border-gray-700'
            } rounded-lg text-white placeholder-gray-500 focus:outline-none focus:ring-2 ${
              error ? 'focus:ring-red-500' : 'focus:ring-purple-500'
            } focus:border-transparent min-h-[48px]`}
            disabled={isLoading}
            aria-describedby="channelInputDescription"
          />
          {error && (
            <div className="absolute inset-y-0 right-3 flex items-center">
              <AlertCircle className="h-5 w-5 text-red-500" aria-hidden="true" />
            </div>
          )}
          <p id="channelInputDescription" className="sr-only">
            Enter a YouTube channel URL, @username, or channel ID.
          </p>
        </div>
        <button 
          type="submit"
          disabled={isLoading}
          className="flex items-center justify-center gap-2 px-6 py-3 bg-gradient-to-r from-purple-600 to-pink-600 rounded-lg font-medium hover:opacity-90 transition-all duration-200 hover:scale-105 min-h-[48px] disabled:opacity-50 disabled:hover:scale-100"
        >
          {isLoading ? (
            <>
              <Loader2 className="h-4 w-4 animate-spin" />
              <span>Analyzing...</span>
            </>
          ) : (
            <>
              <Youtube className="h-4 w-4" />
              <span>Analyze Channel</span>
            </>
          )}
        </button>
      </form>

      {/* Error Message */}
      {error && (
        <div className="flex items-center gap-2 bg-red-900/20 border border-red-800/30 text-red-400 px-4 py-3 rounded-lg">
          <AlertCircle className="h-5 w-5 shrink-0" />
          <p className="text-sm">{error}</p>
        </div>
      )}

      {/* Channel Preview */}
      {channelDetails && (
        <div className="space-y-4">
          <div className="flex items-center gap-2 bg-green-900/20 border border-green-800/30 text-green-400 px-4 py-3 rounded-lg">
            <CheckCircle2 className="h-5 w-5 shrink-0" />
            <p className="text-sm">Channel found! Here's what we discovered:</p>
          </div>
          <ChannelPreview channel={channelDetails} />
        </div>
      )}

      {/* History List */}
      {history.length > 1 && (
        <div className="mt-6">
          <h4 className="text-lg font-semibold text-white mb-2">History</h4>
          <ul className="space-y-2">
            {history.map((entry, index) => (
              <li key={entry.entryId}>
                <button
                  type="button"
                  onClick={() => setCurrentIndex(index)}
                  className={`w-full text-left px-4 py-2 rounded-lg hover:bg-gray-700 transition-colors duration-200 ${
                    index === currentIndex ? 'bg-gray-600' : 'bg-gray-800'
                  }`}
                  aria-current={index === currentIndex ? 'page' : undefined}
                >
                  <div className="flex items-center gap-3">
                    <img
                      src={entry.channel.snippet.thumbnails.default.url}
                      alt={`${entry.channel.snippet.title} Thumbnail`}
                      className="w-6 h-6 rounded-full border-2 border-purple-500"
                    />
                    <div className="flex flex-col">
                      <span className="text-sm text-gray-200">{entry.channel.snippet.title}</span>
                      <span className="text-xs text-gray-400">
                        {new Date(entry.fetchedAt).toLocaleTimeString()}
                      </span>
                    </div>
                  </div>
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}