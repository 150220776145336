// src/lib/scoring.ts

/**
 * ScoringService.ts
 *
 * This service class encapsulates methods to calculate various scores
 * based on YouTube channel and video metrics.
 */

interface EngagementMetrics {
  likeCount: string;
  viewCount: string;
  commentCount: string;
  publishedAt?: string;
}

interface EngagementRatios {
  likesViewRatio: number;
  commentsLikesRatio: number;
  commentsViewRatio: number;
}

interface ViewScoreMetrics {
  totalViews: string;
  videos: string;
  subscribers: string;
  recentVideoViews: string[]; // Array of view counts
}

export class ScoringService {
  /**
   * Parses a metric string into a number.
   * Assumes that the input is a fully expanded number string (e.g., "10000").
   * Returns 0 if parsing fails.
   *
   * @param value - The metric value as a string.
   * @returns The parsed number or 0.
   */
  private static parseMetric(value?: string): number {
    if (!value || typeof value !== 'string') {
      console.warn(`Invalid metric value: "${value}". Defaulting to 0.`);
      return 0;
    }

    // Remove commas for thousands separators, if any
    const cleanedValue = value.replace(/,/g, '');

    const number = parseFloat(cleanedValue);
    if (isNaN(number)) {
      console.warn(`Unable to parse number from value: "${value}". Defaulting to 0.`);
      return 0;
    }

    return number;
  }

  /**
   * Calculates engagement ratios from raw metrics.
   *
   * @param metrics - Engagement metrics for a video.
   * @returns An object containing the calculated ratios.
   */
  private static calculateEngagementRatios(metrics: EngagementMetrics): EngagementRatios {
    const likes = this.parseMetric(metrics.likeCount);
    const views = this.parseMetric(metrics.viewCount);
    const comments = this.parseMetric(metrics.commentCount);

    // Enhanced validation with detailed logging
    if (views === 0) {
      console.warn('Invalid metrics encountered for Engagement Score calculation.');
      return {
        likesViewRatio: 0,
        commentsLikesRatio: 0,
        commentsViewRatio: 0,
      };
    }

    // Calculate the three engagement ratios
    return {
      likesViewRatio: (likes / views) * 100, // Likes/Views %
      commentsLikesRatio: likes > 0 ? (comments / likes) * 100 : 0, // Comments/Likes %
      commentsViewRatio: (comments / views) * 100, // Comments/Views %
    };
  }

  /**
   * Filters and validates video views for scoring
   */
  private static prepareRecentViews(views: string[]): number[] {
    return views
      .slice(0, 25) // Take first 10 videos for consistent scoring
      .map(views => this.parseMetric(views))
      .filter(views => views > 0);
  }

  /**
   * Calculates the Engagement Score for a video.
   *
   * @param metrics - Engagement metrics for a video.
   * @returns The calculated Engagement Score rounded to two decimal places.
   */
  public static calculateEngagementScore(metrics: EngagementMetrics): number {
    const ratios = this.calculateEngagementRatios(metrics);

    // Sum the three percentages to get the final Engagement Score
    const score = ratios.likesViewRatio + ratios.commentsLikesRatio + ratios.commentsViewRatio;

    // Return the score rounded to two decimal places
    return Number(score.toFixed(2));
  }

  /**
   * Determines the color gradient based on the Engagement Score.
   *
   * @param score - The calculated Engagement Score.
   * @returns A string representing Tailwind CSS gradient classes.
   */
  public static getEngagementScoreColor(score: number): string {
    if (score >= 20) return 'from-emerald-500 to-emerald-700'; // Excellent
    if (score >= 15) return 'from-blue-500 to-blue-700';      // Very Good
    if (score >= 10) return 'from-indigo-500 to-indigo-700';  // Good
    if (score >= 5) return 'from-amber-500 to-amber-700';     // Average
    return 'from-red-500 to-red-700';                         // Below Average
  }

  /**
   * Calculates the ChannelReach Score for a channel.
   *
   * @param metrics - Channel metrics for reach calculation
   * @returns The calculated ChannelReach Score as a percentage
   */
  public static calculateChannelReachScore(metrics: ViewScoreMetrics): number {
    const subscribersNum = this.parseMetric(metrics.subscribers);
    const totalViews = this.parseMetric(metrics.totalViews);
    const totalVideos = this.parseMetric(metrics.videos);
    
    if (subscribersNum === 0 || totalVideos === 0) return 0;
    
    // Prepare recent views data
    const recentViews = this.prepareRecentViews(metrics.recentVideoViews);
    if (recentViews.length === 0) return 0;
    
    // Calculate average views from 10 most recent videos
    const recentSum = recentViews.reduce((acc, curr) => acc + curr, 0);
    const recentAvg = recentSum / recentViews.length;
    
    // Calculate historical average (total views / total videos)
    const historicalAvg = totalViews / totalVideos;
    
    // Calculate performance ratio (recent average / historical average)
    const performanceRatio = historicalAvg > 0 ? recentAvg / historicalAvg : 0;
    
    // Calculate subscriber reach (recent average / total subscribers)
    const subscriberReach = subscribersNum > 0 ? recentAvg / subscribersNum : 0;
    
    // Final score is performance ratio * subscriber reach * 100 to get percentage
    const score = performanceRatio * subscriberReach * 100;
    
    return Number(score.toFixed(1));
  }
}