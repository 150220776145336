import { useState } from 'react';
import { Youtube, CheckCircle2, Lock, UserCircle, Mail, Globe, BarChart, Users, Target, Image, HeartHandshake, MessageCircle, Gift, Zap } from 'lucide-react';
import { GoogleButton } from './GoogleButton';
import { YouTubeButton } from './YouTubeButton';

interface Permission {
  icon: typeof Lock;
  name: string;
  description: string;
  crmData?: string;
}

interface AuthStep {
  title: string;
  description: string;
  action: React.ReactNode;
  permissions?: Permission[];
}

export function AuthSteps() {
  const [currentStep, setCurrentStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState<number[]>([]);
  const [showToast, setShowToast] = useState<'google' | 'youtube' | null>(null);

  const isVerificationComplete = completedSteps.includes(0) && completedSteps.includes(1);

  const handleGoogleLogin = () => {
    setShowToast('google');
    setTimeout(() => {
      setShowToast(null);
      setCompletedSteps([...completedSteps, 0]);
      setCurrentStep(1);
    }, 1500);
  };

  const handleYoutubeConnect = () => {
    setShowToast('youtube');
    setTimeout(() => {
      setShowToast(null);
      setCompletedSteps([...completedSteps, 1]);
      if (completedSteps.includes(0)) {
        setCurrentStep(2);
      }
    }, 1500);
  };

  const steps: AuthStep[] = [
    {
      title: "Sign in with Google",
      description: "Securely sign in with your Google account",
      permissions: [
        // Standard Permissions
        {
          icon: UserCircle,
          name: "Verified Identity",
          description: "Basic profile info (Standard)",
          crmData: "Add verified members with real names to your CRM"
        },
        {
          icon: Image,
          name: "Profile Photo",
          description: "Profile picture (Standard)",
          crmData: "Personalize your CRM with member photos"
        },
        {
          icon: Mail,
          name: "Email Database",
          description: "Primary email only (Standard)",
          crmData: "Build a verified email list for direct communication"
        },
        // Optional Permissions
        {
          icon: Target,
          name: "Extended Profile",
          description: "Optional: Requires additional consent",
          crmData: "Create detailed audience segments and interest graphs"
        }
      ],
      action: (
        <GoogleButton
          onClick={handleGoogleLogin}
          disabled={completedSteps.includes(0)}
        />
      )
    },
    {
      title: "Connect YouTube",
      description: "Verify your YouTube subscription status",
      permissions: [
        {
          icon: Users,
          name: "Subscriptions",
          description: "View subscribed channels (Standard)",
          crmData: "Track which creators your members follow"
        },
        {
          icon: Globe,
          name: "Channel Details",
          description: "Basic account info (Standard)",
          crmData: "Verify subscription status and engagement"
        },
        {
          icon: BarChart,
          name: "Liked Videos",
          description: "Public likes data (Optional)",
          crmData: "Understand content preferences and interests"
        },
        {
          icon: Target,
          name: "Playlists",
          description: "Public playlists (Optional)",
          crmData: "Create interest-based audience segments"
        }
      ],
      action: (
        <YouTubeButton
          onClick={handleYoutubeConnect}
          disabled={!completedSteps.includes(0) || completedSteps.includes(1)}
        />
      )
    },
    {
      title: "You're all set!",
      description: "Successfully verified as a subscriber",
      action: (
        <div className="space-y-6">
          <div className="flex flex-col items-center gap-4">
            <div className="relative">
              <div className="flex items-center gap-3">
                <img
                  src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                  alt="Subscriber"
                  className="h-12 w-12 rounded-full border-2 border-green-500"
                />
                <div className="bg-green-900/20 p-2 rounded-full">
                  <HeartHandshake className="h-6 w-6 text-green-500" />
                </div>
                <img
                  src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                  alt="Creator"
                  className="h-12 w-12 rounded-full border-2 border-purple-500"
                />
              </div>
              <div className="absolute -bottom-1 left-1/2 -translate-x-1/2 bg-green-500 text-xs text-black font-medium px-2 py-0.5 rounded-full">
                Connected
              </div>
            </div>
            <div className="text-center">
              <p className="text-green-400 font-medium">Verification Complete!</p>
              <div className="mt-2 space-y-1">
                <p className="text-sm text-gray-300">
                  <span className="text-green-400">@alexhiker</span> → <span className="text-purple-400">@travelwithheidi</span>
                </p>
                <p className="text-xs text-gray-500">Verified Connection Established</p>
              </div>
            </div>
          </div>
          
          <div className="bg-gray-800/50 rounded-lg p-4 border border-gray-700">
            <div className="flex items-center justify-between mb-3">
              <h4 className="text-sm font-medium text-gray-300">Membership Status</h4>
              <span className="text-xs bg-green-900/30 text-green-400 px-2 py-1 rounded-full">Active</span>
            </div>
            <div className="space-y-3">
              <div className="flex items-center gap-3">
                <div className="bg-purple-900/20 p-2 rounded-lg">
                  <Youtube className="h-4 w-4 text-purple-400" />
                </div>
                <div>
                  <p className="text-sm text-gray-300">Verified YouTube Subscriber</p>
                  <p className="text-xs text-gray-500">Subscribed since June 2023</p>
                </div>
              </div>
              <div className="flex items-center gap-3">
                <div className="bg-green-900/20 p-2 rounded-lg">
                  <Users className="h-4 w-4 text-green-400" />
                </div>
                <div>
                  <p className="text-sm text-gray-300">Premium Community Member</p>
                  <p className="text-xs text-gray-500">Full access • Tier 1 Benefits</p>
                </div>
              </div>
            </div>
          </div>
          
          <div className="bg-gradient-to-r from-purple-900/20 to-pink-900/20 rounded-lg p-4 border border-purple-800/30">
            <h4 className="text-sm font-medium text-purple-300 mb-4">What's Next</h4>
            <ul className="space-y-4">
              <li className="flex flex-col sm:flex-row items-start sm:items-center gap-3 text-sm">
                <div className="bg-green-900/20 p-2 rounded-lg shrink-0">
                  <MessageCircle className="h-4 w-4 text-green-400" />
                </div>
                <div className="w-full">
                  <span className="text-green-300 block sm:inline">Community Access:</span>
                  <span className="text-gray-400 block sm:inline mt-1 sm:mt-0 sm:ml-1">Engage directly with Heidi and fellow travelers</span>
                </div>
              </li>
              <li className="flex flex-col sm:flex-row items-start sm:items-center gap-3 text-sm">
                <div className="bg-purple-900/20 p-2 rounded-lg shrink-0">
                  <Gift className="h-4 w-4 text-purple-400" />
                </div>
                <div className="w-full">
                  <span className="text-green-300 block sm:inline">Exclusive Offers:</span>
                  <span className="text-gray-400 block sm:inline mt-1 sm:mt-0 sm:ml-1">Browse creator-sponsored deals in one place</span>
                </div>
              </li>
              <li className="flex flex-col sm:flex-row items-start sm:items-center gap-3 text-sm">
                <div className="bg-blue-900/20 p-2 rounded-lg shrink-0">
                  <Zap className="h-4 w-4 text-blue-400" />
                </div>
                <div className="w-full">
                  <span className="text-green-300 block sm:inline">Smart Benefits:</span>
                  <span className="text-gray-400 block sm:inline mt-1 sm:mt-0 sm:ml-1">Auto-applied discounts and personalized content</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      )
    }
  ];

  return (
    <div className="mt-8 bg-gray-900 p-6 sm:p-8 rounded-xl border border-gray-800">
      {/* Toast Notification */}
      <div className={`
        fixed top-4 right-4 z-50 transition-all duration-300 transform
        ${showToast ? 'translate-y-0 opacity-100' : '-translate-y-4 opacity-0 pointer-events-none'}
      `}>
        <div className="bg-green-900/90 backdrop-blur-sm text-white px-4 py-3 rounded-lg border border-green-700/50 shadow-xl">
          <div className="flex items-center gap-3">
            <div className="bg-green-500/20 p-1.5 rounded-full">
              <CheckCircle2 className="h-4 w-4 text-green-400" />
            </div>
            <div>
              <p className="text-sm font-medium">
                {showToast === 'google' ? 'Google Sign-In Successful' : 'YouTube Connected'}
              </p>
              <p className="text-xs text-green-300">
                {showToast === 'google' ? 'Connecting to YouTube...' : 'Verifying subscription status...'}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="text-xs text-gray-400 flex items-center gap-2 mb-4">
        <Lock className="h-4 w-4" />
        <span>Standard permissions are required. Extended permissions are optional and can be skipped.</span>
        {!isVerificationComplete && (
          <span className="ml-auto text-purple-400">Complete both steps to verify</span>
        )}
      </div>
      {steps.map((step, index) => (
        <div
          key={step.title}
          className={`relative flex gap-4 sm:gap-6 ${
            index !== steps.length - 1 ? 'pb-6' : ''
          } ${index === 2 && !isVerificationComplete ? 'opacity-50 pointer-events-none' : ''}`}
        >
          {/* Connector Line */}
          {index !== steps.length - 1 && (
            <div className="absolute left-6 top-12 bottom-0 w-0.5 bg-gray-800" />
          )}
          
          {/* Step Number */}
          <div className="relative z-10">
            <div className={`
              h-12 w-12 rounded-full flex items-center justify-center border-2
              ${completedSteps.includes(index)
                ? 'bg-green-900/20 border-green-500 text-green-400'
                : currentStep === index
                ? 'bg-purple-900/20 border-purple-500 text-purple-400'
                : 'bg-gray-800 border-gray-700 text-gray-600'
              }
            `}>
              {completedSteps.includes(index) ? (
                <CheckCircle2 className="h-6 w-6" />
              ) : (
                <span className="text-lg font-medium">{index + 1}</span>
              )}
            </div>
          </div>

          {/* Step Content */}
          <div className="flex-1">
            <h3 className="text-lg font-medium text-white mb-1">
              {index === 2 && !isVerificationComplete ? 'Verification Required' : step.title}
            </h3>
            <p className="text-sm text-gray-400 mb-4">
              {index === 2 && !isVerificationComplete 
                ? 'Complete both steps above to verify your membership'
                : step.description}
            </p>
            {step.permissions && (
              <div className="my-4 grid grid-cols-1 gap-3">
                {step.permissions.map((permission) => (
                  <div key={permission.name} className="bg-gray-800/50 rounded-lg p-4 border border-gray-700 hover:border-gray-600 transition-all duration-200">
                    <div className="flex flex-col sm:flex-row sm:items-center gap-3 mb-2">
                      <div className="bg-gray-700/50 p-2 rounded-lg w-fit">
                        <permission.icon className={`h-5 w-5 ${
                          permission.description.includes('Standard')
                            ? 'text-blue-400'
                            : 'text-purple-400'
                        }`} />
                      </div>
                      <div>
                        <p className="font-medium text-gray-200">{permission.name}</p>
                        <p className={`text-sm ${
                          permission.description.includes('Standard') ? 'text-blue-400' :
                          permission.description.includes('Optional') ? 'text-orange-400' :
                          'text-purple-400'
                        }`}>
                          {permission.description}
                          {permission.description.includes('Optional') && (
                            <span className="block mt-1 text-xs text-gray-400">
                              Demographics, interests, and preferences data
                            </span>
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="pl-0 sm:pl-10">
                      {permission.crmData && (
                        <div className="flex items-center gap-2 text-sm">
                          <span className={`h-1.5 w-1.5 rounded-full ${
                            permission.description.includes('Standard')
                              ? 'bg-blue-400'
                              : 'bg-purple-400'
                          }`} />
                          <span className="text-purple-300">{permission.crmData}</span>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
            {(index !== 2 || isVerificationComplete) && step.action}
            {index === 2 && !isVerificationComplete && (
              <div className="text-center p-4 sm:p-6 bg-gray-800/50 rounded-lg border border-gray-700">
                <Lock className="h-8 w-8 text-gray-500 mx-auto mb-2" />
                <p className="text-sm text-gray-400">
                  Complete Google and YouTube verification to unlock membership benefits
                </p>
              </div>
            )}
          </div>
        </div>
      ))}
      <div className="mt-4 pt-4 border-t border-gray-800">
        <p className="text-xs text-gray-500">
          <span className="flex items-center gap-2">
            <Lock className="h-3 w-3" />
            Your data is encrypted and secure. Additional permissions can be granted or revoked at any time.
          </span>
        </p>
      </div>
    </div>
  );
}