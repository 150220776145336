import {
  signInWithPopup, 
  signInWithRedirect,
  getRedirectResult,
  onAuthStateChanged,
  GoogleAuthProvider,
  type User
} from 'firebase/auth';
import type { FirebaseError } from 'firebase/app';
import { auth } from './firebase';

const AUTH_STATE = {
  REDIRECT_PENDING: 'auth_redirect_pending',
  PENDING_URL: 'pendingUrl',
  ACCESS_TOKEN: 'youtube_access_token'
};

const provider = new GoogleAuthProvider();

// Configure required scopes
provider.addScope('email');
provider.addScope('profile');
provider.addScope('https://www.googleapis.com/auth/youtube.readonly');
provider.addScope('https://www.googleapis.com/auth/youtube.force-ssl');

provider.setCustomParameters({
  prompt: 'select_account',
  access_type: 'offline',
  include_granted_scopes: 'true'
});

// Check for redirect result on page load
export async function checkRedirectResult(): Promise<User | null> {
  console.log('checkRedirectResult: Initiating redirect result check.');

  if (!localStorage.getItem(AUTH_STATE.REDIRECT_PENDING)) {
    console.log('checkRedirectResult: No redirect pending.');
    return null;
  }

  try {
    const result = await getRedirectResult(auth);
    console.log('checkRedirectResult: getRedirectResult completed.', { result });

    // Clear pending state regardless of result
    localStorage.removeItem(AUTH_STATE.REDIRECT_PENDING);

    if (result) {
      const credential = GoogleAuthProvider.credentialFromResult(result);
      if (credential?.accessToken) {
        console.log('checkRedirectResult: Access token obtained.', { accessToken: credential.accessToken });
        localStorage.setItem(AUTH_STATE.ACCESS_TOKEN, credential.accessToken);
      }
      return result.user;
    }

    console.warn('checkRedirectResult: No user found in redirect result.');
    return null;
  } catch (error) {
    console.error('checkRedirectResult: Redirect authentication error:', error);
    localStorage.removeItem(AUTH_STATE.REDIRECT_PENDING); // Ensure state is cleared
    return null;
  }
}


export async function signInWithGoogle(): Promise<User | null> {
  try {
    console.log('signInWithGoogle: Attempting popup authentication.');
    const result = await signInWithPopup(auth, provider);
    console.log('signInWithGoogle: Popup authentication successful.', { result });

    const credential = GoogleAuthProvider.credentialFromResult(result);
    if (!credential?.accessToken) {
      console.error('signInWithGoogle: Failed to get access token.');
      throw new Error('Failed to get access token. Please try again.');
    }

    // Store the access token for YouTube API calls
    localStorage.setItem(AUTH_STATE.ACCESS_TOKEN, credential.accessToken);
    return result.user;
  } catch (error) {
    console.error('signInWithGoogle: Authentication error encountered.', {
      error: error instanceof Error ? error.message : error,
    });

    const firebaseError = error as FirebaseError;

    if (firebaseError?.code === 'auth/popup-blocked') {
      console.warn('signInWithGoogle: Popup blocked. Falling back to redirect.');
      localStorage.setItem(AUTH_STATE.REDIRECT_PENDING, 'true'); // Mark redirect as pending
      setTimeout(() => {
        alert('Popup blocked. Redirecting to Google sign-in...');
        signInWithRedirect(auth, provider); // Trigger redirect authentication
      }, 500); // Delay for user notification
      return null;
    }

    // Handle other Firebase Auth errors
    throw firebaseError;
  }
}



// Listen for auth state changes
export function onAuthStateChange(callback: (user: User | null) => void) {
  console.log('onAuthStateChange: Setting up listener for auth state changes.');
  return onAuthStateChanged(auth, (user) => {
    console.log('onAuthStateChange: Auth state changed.', { user });
    callback(user);
  });
}
