import { Users, Mail, Info, Target } from 'lucide-react';

export function GoogleOAuthData() {
  return (
    <div className="bg-gradient-to-br from-blue-50 to-blue-100 rounded-lg p-4 shadow-sm w-full">
      <div className="flex items-center gap-2 mb-4">
        <div className="flex gap-1">
          <div className="w-4 h-4">
            <svg viewBox="0 0 24 24">
              <path
                d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                fill="#4285F4"
              />
              <path
                d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                fill="#34A853"
              />
              <path
                d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                fill="#FBBC05"
              />
              <path
                d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                fill="#EA4335"
              />
            </svg>
          </div>
          <h4 className="text-sm font-medium text-gray-900">Google OAuth Data</h4>
        </div>
        <span className="text-xs bg-blue-100 text-blue-600 px-2 py-0.5 rounded-full ml-auto">Standard Permissions</span>
      </div>
      
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div className="bg-white p-4 rounded-lg border border-gray-100">
          <div className="flex items-center gap-2 mb-2">
            <div className="p-1.5 bg-blue-50 rounded">
              <Users className="h-4 w-4 text-blue-500" />
            </div>
            <div>
              <p className="text-sm font-medium text-gray-900">Verified Identity</p>
              <p className="text-xs text-blue-500">Standard Permission</p>
            </div>
          </div>
          <p className="text-xs text-gray-500 mt-1">Full name and profile photo from Google account</p>
          <div className="mt-2 text-xs text-gray-400 flex items-center gap-1">
            <Info className="h-3 w-3" />
            <span>Add verified members to your CRM</span>
          </div>
        </div>
        
        <div className="bg-white p-4 rounded-lg border border-gray-100">
          <div className="flex items-center gap-2 mb-2">
            <div className="p-1.5 bg-blue-50 rounded">
              <Mail className="h-4 w-4 text-blue-500" />
            </div>
            <div>
              <p className="text-sm font-medium text-gray-900">Email Database</p>
              <p className="text-xs text-blue-500">Standard Permission</p>
            </div>
          </div>
          <p className="text-xs text-gray-500 mt-1">Primary email address for communications</p>
          <div className="mt-2 text-xs text-gray-400 flex items-center gap-1">
            <Info className="h-3 w-3" />
            <span>Build a verified email list</span>
          </div>
        </div>
      </div>
      
      <div className="mt-4 bg-white p-4 rounded-lg border border-gray-100">
        <div className="flex items-center gap-2 mb-2">
          <div className="p-1.5 bg-purple-50 rounded">
            <Target className="h-4 w-4 text-purple-500" />
          </div>
          <div>
            <p className="text-sm font-medium text-gray-900">Extended Profile</p>
            <p className="text-xs text-purple-500">Optional Permission</p>
          </div>
          <span className="ml-auto text-xs bg-gray-100 text-gray-600 px-2 py-0.5 rounded-full">Not Granted</span>
        </div>
        <p className="text-xs text-gray-500 mt-1">Demographics, interests, and preferences data</p>
        <div className="mt-2 text-xs text-gray-400 flex items-center gap-1">
          <Info className="h-3 w-3" />
          <span>Create detailed audience segments</span>
        </div>
      </div>
      
      <div className="mt-3 text-xs text-gray-500 flex items-center gap-1">
        <Info className="h-3 w-3" />
        <span>Data available through Google OAuth 2.0</span>
      </div>
    </div>
  );
}