import { Sparkles, Target, Users, BarChart3, TrendingUp, ArrowUpRight, Download, Mail, Calendar, Globe2, Youtube } from 'lucide-react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Cell } from 'recharts';
import { competitorAnalysis, audienceOverlap, brandAffinityData, partnershipTiers, pastDeals } from '../../data/mockData';

const COLORS = ['#818CF8', '#F472B6', '#34D399', '#FBBF24'];

const metrics = [
  {
    label: 'Audience Match',
    value: '92%',
    trend: '+5.2%',
    description: 'Brand affinity score',
    icon: Target,
    color: 'from-purple-400/90 to-pink-400/90'
  },
  {
    label: 'Verified Reach',
    value: '156K',
    trend: '+12.5%',
    description: 'Active subscribers',
    icon: Users,
    color: 'from-blue-400/90 to-cyan-400/90'
  },
  {
    label: 'Conversion Rate',
    value: '8.5%',
    trend: '+3.2%',
    description: 'Across all promos',
    icon: BarChart3,
    color: 'from-green-400/90 to-emerald-400/90'
  }
];

export function BrandReport() {
  return (
    <div className="bg-gray-900 border-t border-gray-800">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 sm:py-24">
        <div className="max-w-4xl mx-auto relative">
          <div className="absolute inset-0 bg-gradient-to-br from-gray-400/5 to-gray-500/5 rounded-xl blur-xl" />

          {/* Email Header */}
          <div className="relative bg-gradient-to-br from-white to-gray-50 rounded-t-xl border border-gray-200 p-6 sm:p-8 shadow-lg">
            <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-6 mb-6">
              <div className="flex items-start sm:items-center gap-4 w-full sm:w-auto">
                <div className="h-12 w-12 rounded-xl bg-gradient-to-br from-indigo-500 to-blue-500 flex items-center justify-center shadow-lg shrink-0">
                  <Sparkles className="h-6 w-6 text-white" />
                </div>
                <div>
                  <h2 className="text-xl font-bold text-gray-900">Partnership Proposal</h2>
                  <p className="text-sm text-gray-600">Generated by Subscribr AI</p>
                </div>
              </div>
              <button className="w-full sm:w-auto flex items-center justify-center gap-2 px-4 py-2 bg-gradient-to-r from-indigo-500 to-blue-500 text-white rounded-lg hover:opacity-90 transition-all duration-200 shadow-md">
                <Download className="h-4 w-4" />
                <span>Export PDF</span>
              </button>
            </div>
            
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 text-sm text-gray-600 mt-6">
              <div className="flex items-center gap-2">
                <Mail className="h-4 w-4 shrink-0" />
                <span className="truncate">partnerships@peakdesign.com</span>
              </div>
              <div className="flex items-center gap-2">
                <Calendar className="h-4 w-4 shrink-0" />
                <span className="truncate">Generated on March 20, 2024</span>
              </div>
              <div className="flex items-center gap-2">
                <Globe2 className="h-4 w-4 shrink-0" />
                <span className="truncate">subscribr.xyz/@heidi</span>
              </div>
              <div className="flex items-center gap-2">
                <Youtube className="h-4 w-4 shrink-0" />
                <span className="truncate">156K Verified Subscribers</span>
              </div>
            </div>
          </div>
          
          {/* Email Body */}
          <div className="relative bg-white p-6 sm:p-8 border-x border-gray-200">
            <div className="prose max-w-none">
              <p className="text-gray-700 leading-relaxed">
                Dear Peak Design Team,
              </p>
              <p className="text-gray-600 leading-relaxed mt-4">
                I hope this email finds you well. I'm reaching out because our data shows a compelling opportunity for partnership between Peak Design and my travel-focused YouTube channel. Using Subscribr's AI-powered analytics, I've identified significant brand alignment and conversion potential that I believe would make for a mutually beneficial collaboration.
              </p>
              
              <h3 className="text-lg font-semibold text-gray-900 mt-8 mb-4">Why Peak Design + My Channel?</h3>
              
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
                {metrics.map((metric) => (
                  <div key={metric.label} className="bg-gradient-to-br from-white to-gray-50 rounded-xl p-6 border border-gray-200 shadow-md hover:shadow-lg transition-all duration-200">
                    <div className="flex items-start justify-between mb-4">
                      <div className={`p-3 rounded-lg bg-gradient-to-br ${metric.color}`}>
                        <metric.icon className="h-5 w-5 text-white" />
                      </div>
                      <span className="text-sm bg-green-100 text-green-600 px-3 py-1 rounded-full">
                        {metric.trend}
                      </span>
                    </div>
                    <h3 className="text-2xl font-bold text-gray-900 mb-1">{metric.value}</h3>
                    <p className="text-sm text-gray-700 mb-2">{metric.label}</p>
                    <p className="text-xs text-gray-500">{metric.description}</p>
                  </div>
                ))}
              </div>
              
              <h3 className="text-lg font-semibold text-gray-900 mt-8 mb-4">Audience Analysis</h3>
              
              <div className="bg-white rounded-xl p-6 border border-gray-200 mb-8">
                <div className="h-[300px] mb-6">
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={brandAffinityData} barSize={40}>
                      <CartesianGrid strokeDasharray="3 3" stroke="#D1D5DB" />
                      <XAxis dataKey="category" stroke="#9CA3AF" />
                      <YAxis stroke="#9CA3AF" />
                      <Tooltip
                        contentStyle={{
                          backgroundColor: '#FFFFFF',
                          border: '1px solid #D1D5DB',
                          borderRadius: '0.5rem'
                        }}
                        itemStyle={{ color: '#4B5563' }}
                        labelStyle={{ color: '#9CA3AF' }}
                      />
                      <Bar dataKey="affinity" name="Brand Affinity" fill={COLORS[0]}>
                        {brandAffinityData.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div className="bg-gray-100 rounded-lg p-4 border border-gray-200">
                    <h4 className="text-sm font-medium text-purple-500 mb-2">Key Demographics</h4>
                    <ul className="space-y-2 text-sm text-gray-700">
                      <li>• 85% aged 25-34, urban professionals</li>
                      <li>• 72% frequent travelers (4+ trips/year)</li>
                      <li>• 65% own professional camera equipment</li>
                    </ul>
                  </div>
                  <div className="bg-gray-100 rounded-lg p-4 border border-gray-200">
                    <h4 className="text-sm font-medium text-purple-500 mb-2">Purchase Behavior</h4>
                    <ul className="space-y-2 text-sm text-gray-700">
                      <li>• $250+ average order value</li>
                      <li>• 8.5% conversion on premium gear</li>
                      <li>• 92% brand affinity score</li>
                    </ul>
                  </div>
                </div>
              </div>

              <h3 className="text-lg font-semibold text-gray-900 mt-8 mb-4">Partnership Tiers</h3>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
                {partnershipTiers.map((tier) => (
                  <div key={tier.name} className="bg-gradient-to-br from-white to-gray-50 rounded-xl p-6 border border-gray-200 shadow-md">
                    <div className="flex items-center justify-between mb-4">
                      <h4 className="text-lg font-semibold text-gray-900">{tier.name}</h4>
                      <span className="text-lg font-bold text-purple-600">{tier.price}</span>
                    </div>
                    <ul className="space-y-2">
                      {tier.includes.map((item, index) => (
                        <li key={index} className="flex items-center gap-2 text-sm text-gray-600">
                          <span className="h-1.5 w-1.5 rounded-full bg-purple-500" />
                          {item}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>

              <h3 className="text-lg font-semibold text-gray-900 mt-8 mb-4">Past Partnership Success</h3>
              
              <div className="space-y-6 mb-8">
                {pastDeals.map((deal) => (
                  <div key={deal.brand} className="bg-gradient-to-br from-white to-gray-50 rounded-xl p-6 border border-gray-200">
                    <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4 mb-4">
                      <div>
                        <h4 className="text-lg font-semibold text-gray-900">{deal.brand}</h4>
                        <p className="text-sm text-purple-600">{deal.deal}</p>
                      </div>
                      <div className="text-right">
                        <p className="text-lg font-bold text-green-600">{deal.results.revenue}</p>
                        <p className="text-sm text-gray-600">Generated Revenue</p>
                      </div>
                    </div>
                    
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
                      <div className="bg-gray-50 rounded-lg p-3">
                        <p className="text-sm text-gray-600">Conversion Rate</p>
                        <p className="text-lg font-semibold text-gray-900">{deal.results.conversion}</p>
                      </div>
                      <div className="bg-gray-50 rounded-lg p-3">
                        <p className="text-sm text-gray-600">ROI</p>
                        <p className="text-lg font-semibold text-gray-900">{deal.results.roi}</p>
                      </div>
                    </div>
                    
                    <div className="bg-purple-50 rounded-lg p-4 border border-purple-100">
                      <p className="text-sm text-gray-700 italic mb-2">"{deal.testimonial}"</p>
                      <p className="text-sm text-purple-600">- {deal.contact}</p>
                    </div>
                  </div>
                ))}
              </div>

              <div className="bg-gradient-to-br from-purple-100 to-pink-100 rounded-xl p-6 border border-purple-200">
                <h4 className="text-sm font-medium text-purple-500 mb-2">AI-Generated Recommendation</h4>
                <p className="text-sm text-gray-700">
                  Our AI analysis reveals a compelling opportunity: Your audience's verified purchase history shows a strong preference for premium travel gear ($250+ AOV) and professional photography equipment (65% ownership rate). Cross-referencing with similar brand partnerships in our network, we project an 8.5% conversion rate—significantly above the industry average of 2.3% for travel gear collaborations. The data suggests that a Peak Design partnership would not only drive immediate sales but also foster long-term brand loyalty among your highly-qualified audience of travel photographers and adventure enthusiasts.
                </p>
              </div>

              <p className="text-gray-700 leading-relaxed mt-8 text-sm">
                I would welcome the opportunity to walk you through our detailed analytics dashboard and discuss how we can structure a data-driven partnership that delivers measurable ROI. Would you be available for a 30-minute call next week to explore this opportunity further?
              </p>

              <p className="text-gray-700 leading-relaxed mt-8 text-sm">
                Best regards,<br />
                Heidi<br />
                Travel Creator & Adventure Guide<br />
                156K Verified Subscribers | 8.5% Avg. Conversion Rate
              </p>
            </div>
          </div>

          {/* Email Footer */}
          <div className="relative bg-gradient-to-br from-white to-gray-50 rounded-b-xl border border-t-0 border-gray-200 p-6">
            <div className="flex flex-col sm:flex-row items-center justify-between gap-4 text-sm text-gray-600">
              <div className="flex items-center gap-2">
                <Sparkles className="h-4 w-4" />
                <span>Generated by Subscribr AI</span>
              </div>
              <span>March 20, 2024</span>
            </div>
          </div>
          
          <div className="absolute inset-0 pointer-events-none">
            <div className="absolute inset-0 bg-gradient-to-br from-gray-400/3 to-gray-500/3 rounded-xl" />
          </div>
        </div>
      </div>
    </div>
  );
}