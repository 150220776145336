import type { VideoAnalysis } from '../types';

export const transcriptAnalysis: VideoAnalysis = {
  recentVideos: [
    {
      title: "The Art of Spending Money | Prof G Markets",
      thumbnail: "https://i.ytimg.com/vi/J7d1qC6D7JM/maxresdefault.jpg",
      channel: {
        name: "The Prof G Show – Scott Galloway",
        avatar: "https://yt3.googleusercontent.com/ytc/AIdro_lpRTSrDSUd5pkj2SFUkCjkB-x8Vf1frzWpl0cBwW_mog",
        handle: "@TheProfGShow",
        subscribers: "302K",
        videoCount: 443
      },
      views: "58K",
      uploadedAt: "2 days ago",
      transcripts: [
        "Support for Prof G Markets comes from fundrise... a more than $125 million fund designed specifically for individual investors... carefully consider the investment material before investing.",
        "Support for the show comes from zbiotics... invented by PhD scientists to tackle rough mornings after drinking... Go to zbiotics.com to learn more and get 15% off your first order when you use Prof G at checkout."
      ],
      brands: ["Fundrise", "Zbiotics"],
      products: ["Innovation Fund", "Pre-Alcohol Probiotic"],
      sponsorships: [
        { brand: "Fundrise", type: "Paid Advertisement", sentiment: "Very Positive" },
        { brand: "Zbiotics", type: "Primary Sponsor", sentiment: "Very Positive" }
      ]
    },
    {
      title: "Content Creation Masterclass 2024",
      thumbnail: "https://i.ytimg.com/vi/X0BCxa3V67M/hqdefault.jpg?sqp=-oaymwEnCNACELwBSFryq4qpAxkIARUAAIhCGAHYAQHiAQoIGBACGAY4AUAB&rs=AOn4CLBPhoOg_chNsUQuyhcAuJrYX14dkw",
      channel: {
        name: "My First Million",
        avatar: "https://yt3.googleusercontent.com/S1Hn_q8IGAIYOMitrKGMfGNopYwhaYATEhQUqXyac1t2t0WPMqCSZmCMdHU4bsqli0H1X6nM",
        handle: "@MyFirstMillionPod"
      },
      transcript: "Really quick so back when I was running the hustle we had this premium newsletter called Trends... HubSpot did something kind of cool so they took this Playbook that we developed and we gave to our analysts and they turned it into an actionable guide... If you want to check it out you can see the link down below in the description now back to the show.",
      brands: ["HubSpot", "The Hustle"],
      products: ["Trends Newsletter", "Content Playbook"],
      sentiment: "Positive",
      views: "108K",
      sponsorships: [
        { brand: "HubSpot", type: "Primary Sponsor", sentiment: "Very Positive" }
      ]
    },
    {
      title: "Best Camera Gear for Travel 2024",
      thumbnail: "https://images.unsplash.com/photo-1516035069371-29a1b244cc32?ixlib=rb-1.2.1&auto=format&fit=crop&w=1200&q=80",
      channel: {
        name: "Heidi Travels",
        avatar: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
        handle: "@heiditravels"
      },
      transcript: "This video is sponsored by Peak Design! Today I'm excited to show you my complete camera setup. I've been using the Sony A7IV paired with Peak Design's incredible Travel Tripod. For storage, I'm supported by Samsung with their Pro Plus SD cards that have never let me down...",
      brands: ["Sony", "Peak Design", "Samsung"],
      products: ["A7IV Camera", "Travel Tripod", "Pro Plus SD Cards"],
      sentiment: "Positive",
      views: "156K",
      sponsorships: [
        { brand: "Peak Design", type: "Primary Sponsor", sentiment: "Very Positive" }
      ]
    },
    {
      title: "What's in my Travel Backpack?",
      thumbnail: "https://images.unsplash.com/photo-1553531384-cc64ac80f931?ixlib=rb-1.2.1&auto=format&fit=crop&w=1200&q=80",
      channel: {
        name: "Heidi Travels",
        avatar: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
        handle: "@heiditravels"
      },
      transcript: "Thanks to Away for supporting this video! The Peak Design Travel Backpack 45L is a game-changer. Inside, I keep my DJI Mini 3 Pro for aerial shots, and my MacBook Pro for editing. I'm also excited to show you these amazing packing cubes from Away that keep everything organized...",
      brands: ["Peak Design", "DJI", "Apple", "Away"],
      products: ["Travel Backpack 45L", "Mini 3 Pro", "MacBook Pro", "Packing Cubes"],
      sentiment: "Very Positive",
      views: "142K",
      sponsorships: [
        { brand: "Away", type: "Primary Sponsor", sentiment: "Very Positive" }
      ]
    }
  ]
};