import type { LucideIcon } from 'lucide-react';

interface YouTubeButtonProps {
  onClick: () => void;
  disabled?: boolean;
}

export function YouTubeButton({ onClick, disabled }: YouTubeButtonProps) {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`
        w-full flex items-center justify-center gap-3 px-4 py-3 
        rounded-lg shadow-sm bg-white text-gray-600 transition-all duration-200
        ${disabled 
          ? 'opacity-50 cursor-not-allowed' 
          : 'hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 hover:scale-[1.02]'
        }
      `}
    >
      <svg className="h-5 w-5" viewBox="0 0 24 24">
        <path
          d="M23.498 6.186a3.016 3.016 0 0 0-2.122-2.136C19.505 3.545 12 3.545 12 3.545s-7.505 0-9.377.505A3.017 3.017 0 0 0 .502 6.186C0 8.07 0 12 0 12s0 3.93.502 5.814a3.016 3.016 0 0 0 2.122 2.136c1.871.505 9.376.505 9.376.505s7.505 0 9.377-.505a3.015 3.015 0 0 0 2.122-2.136C24 15.93 24 12 24 12s0-3.93-.502-5.814zM9.545 15.568V8.432L15.818 12l-6.273 3.568z"
          fill="#FF0000"
        />
      </svg>
      <span className="text-sm font-medium">
        {disabled ? 'Connected with' : 'Sign up with'} YouTube
      </span>
    </button>
  );
}