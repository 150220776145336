// src/components/forms/ChannelPreview.tsx

import { memo, useEffect, useState } from 'react';
import { Youtube, Users, PlaySquare, CheckCircle, Eye, ThumbsUp, MessageCircle, Calendar, Loader2, Sparkles, ChevronLeft, ChevronRight } from 'lucide-react';
import { decodeHtmlEntities } from '../../lib/formatting';
import { EngagementScoreOverlay } from '../video/EngagementScoreOverlay';
import type { YouTubeChannelDetails, YouTubeComment } from '../../types/youtube';
import { getVideoComments, getChannelVideos } from '../../lib/youtube';
import { AIService } from '../../lib/gemini';
import { videoCache } from '../../lib/cache';

interface ChannelPreviewProps {
  channel: YouTubeChannelDetails;
}

export const ChannelPreview = memo(({ channel }: ChannelPreviewProps) => {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [isLoadingMoreVideos, setIsLoadingMoreVideos] = useState(false);
  const [comments, setComments] = useState<YouTubeComment[]>([]);
  const [isLoadingComments, setIsLoadingComments] = useState(false);
  const [commentsError, setCommentsError] = useState<string | null>(null);
  const [summary, setSummary] = useState<string | null>(null);
  const [isGeneratingSummary, setIsGeneratingSummary] = useState(false);
  const [summaryError, setSummaryError] = useState<string | null>(null);

  // Reset comments when channel changes
  useEffect(() => {
    setCurrentVideoIndex(0);
    videoCache.clearChannel(channel.id);
    resetVideoState();
  }, [channel.id]);

  // Reset state when changing videos
  useEffect(() => {
    const currentVideo = channel.videos?.[currentVideoIndex];
    if (!currentVideo) {
      resetVideoState();
      return;
    }
    
    // Check cache for comments
    const cachedComments = videoCache.getComments(channel.id, currentVideo.id);
    if (cachedComments) {
      setComments(cachedComments);
      
      // Check for cached summary
      const cachedSummary = videoCache.getSummary(channel.id, currentVideo.id);
      if (cachedSummary) {
        setSummary(cachedSummary);
      }
    } else {
      resetVideoState();
    }
  }, [currentVideoIndex]);

  const resetVideoState = () => {
    setComments([]);
    setIsLoadingComments(false);
    setCommentsError(null);
    setSummary(null);
    setIsGeneratingSummary(false);
    setSummaryError(null);
  };

  const handleGenerateSummary = async () => {
    if (isGeneratingSummary || !comments.length) return;
    
    const currentVideo = channel.videos?.[currentVideoIndex];
    const videoContext = currentVideo ? {
      title: currentVideo.title,
      description: currentVideo.description,
      publishedAt: currentVideo.publishedAt,
      viewCount: currentVideo.viewCount,
      likeCount: currentVideo.likeCount
    } : undefined;
    
    setIsGeneratingSummary(true);
    setSummaryError(null);
    
    try {
      const aiService = new AIService();
      const commentsText = comments
        .map(c => `${c.authorDisplayName}: ${c.textDisplay}`)
        .join('\n\n');
      
      const summaryText = await aiService.summarizeComments(commentsText, videoContext);
      setSummary(summaryText);
      
      // Cache the summary
      if (currentVideo) {
        videoCache.setSummary(channel.id, currentVideo.id, summaryText);
      }
    } catch (error) {
      setSummaryError(error instanceof Error ? error.message : 'Failed to generate summary');
    } finally {
      setIsGeneratingSummary(false);
    }
  };

  const handleLoadComments = async () => {
    const currentVideo = channel.videos?.[currentVideoIndex];
    if (!currentVideo?.id || isLoadingComments) return;
    
    setIsLoadingComments(true);
    setCommentsError(null);
    
    try {
      const videoComments = await getVideoComments(currentVideo.id);
      setComments(videoComments);
      
      // Cache the comments
      videoCache.setComments(channel.id, currentVideo.id, videoComments);
    } catch (error) {
      setCommentsError(error instanceof Error ? error.message : 'Failed to load comments');
    } finally {
      setIsLoadingComments(false);
    }
  };

  const handleLoadMoreVideos = async () => {
    if (!channel.id || isLoadingMoreVideos) return;
    
    const currentVideos = channel.videos || [];
    setIsLoadingMoreVideos(true);
    
    try {
      const { videos, nextPageToken } = await getChannelVideos(channel.id, channel.nextPageToken);
      if (videos.length > 0) {
        // Append new videos while maintaining the original 10 for scoring
        channel.videos = [
          ...currentVideos.slice(0, 10), // Keep first 10 for scoring
          ...currentVideos.slice(10),    // Keep any existing paginated videos
          ...videos                      // Add new videos
        ];
        channel.nextPageToken = nextPageToken;
      }
    } catch (error) {
      console.error('Error loading more videos:', error);
    } finally {
      setIsLoadingMoreVideos(false);
    }
  };

  const currentVideo = channel.videos?.[currentVideoIndex];
  const hasMoreVideos = currentVideoIndex < (channel.videos?.length || 0) - 1;
  const canLoadMore = !!channel.nextPageToken;

  // Utility function for formatting numbers
  const formatNumber = (num: string | undefined): string => {
    if (!num) return '0';
    const n = parseInt(num, 10);
    if (isNaN(n)) return '0';
    return new Intl.NumberFormat('en-US', { notation: 'compact' }).format(n);
  };

  // Format date to be more readable
  const formatDate = (dateString: string | undefined): string => {
    if (!dateString) return 'Unknown';
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    }).format(date);
  };

  // Destructure necessary data with fallback values
  const {
    snippet: {
      title = 'Unknown Title',
      thumbnails,
      customUrl = '',
      description = 'No description available.',
      publishedAt,
    } = {},
    statistics: {
      subscriberCount = '0',
      videoCount = '0',
      viewCount = '0',
    } = {},
    id, // Channel ID
  } = channel;

  // Construct channel URL
  const channelUrl = customUrl
    ? `https://www.youtube.com/${customUrl}`
    : `https://www.youtube.com/channel/${id}`;
    
  return (
    <div className="bg-gray-800/50 rounded-xl p-4 sm:p-6 border border-gray-700 hover:shadow-lg transition-all duration-300">
      <div className="flex flex-col gap-8">
        {/* Channel Header */}
        <div className="flex flex-col sm:flex-row items-center sm:items-start gap-4 sm:gap-6">
          {/* Channel Thumbnail */}
          <a
            href={channelUrl}
            target="_blank"
            rel="noopener noreferrer"
            aria-label={`Visit ${title}'s YouTube Channel`}
            className="shrink-0 sm:w-24"
          >
            <img
              src={thumbnails?.medium?.url || '/default-thumbnail.png'}
              alt={`${title} Thumbnail`}
              className="w-20 h-20 sm:w-24 sm:h-24 rounded-full border-2 border-purple-500 object-cover hover:scale-105 transition-transform duration-200 mx-auto"
              loading="lazy"
            />
            
          </a>

          {/* Channel Information */}
          <div className="flex-1 w-full sm:w-auto">
            <div className="flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left gap-2">
              <div>
                <a
                  href={channelUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center gap-2 hover:opacity-90 transition-opacity"
                >
                  <h3 className="font-semibold text-white text-xl sm:text-2xl hover:underline">
                    {title}
                  </h3>
                  {/* Verification Badge Placeholder */}
                  {/* Uncomment and implement verification logic if available */}
                  {/* {isVerified && (
                    <CheckCircle className="h-5 w-5 text-blue-500" aria-label="Verified Channel" />
                  )} */}
                </a>
                <p className="text-sm text-gray-400 flex items-center justify-center sm:justify-start gap-1 mt-1">
                  <Youtube className="h-4 w-4 text-red-500" />
                  {customUrl || id}
                </p>
              </div>
              {/* Subscribe Button Placeholder */}
              {/* Implement if handling user authentication */}
              {/* <button className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700">
                Subscribe
              </button> */}
            </div>

            {/* Channel Description */}
            <p className="mt-4 text-gray-300 text-sm sm:text-base line-clamp-10 sm:line-clamp-none text-center sm:text-left">
              {description}
            </p>

            {/* Channel Statistics */}
            <div className="mt-4 sm:mt-6 grid grid-cols-2 xs:grid-cols-2 sm:grid-cols-2 gap-3 sm:gap-4">
              <StatCard
                variant="primary"
                icon={<Users className="h-5 w-5 text-gray-400" />}
                label="Subscribers"
                value={formatNumber(subscriberCount)}
              />
              <StatCard
                variant="secondary"
                icon={<PlaySquare className="h-5 w-5 text-gray-400" />}
                label="Videos"
                value={formatNumber(videoCount)}
              />
              <StatCard
                variant="secondary"
                icon={<Youtube className="h-5 w-5 text-gray-400" />}
                label="Total Views"
                value={formatNumber(viewCount)}
              />
              {publishedAt && (
                <StatCard
                  variant="secondary"
                  icon={<CheckCircle className="h-5 w-5 text-gray-400" />}
                  label="Joined"
                  value={formatDate(publishedAt)}
                />)}
            </div>
          </div>
        </div>

        {/* Latest Video Section */}
        {currentVideo && (
          <div className="w-full">
            <div className="flex flex-wrap items-center gap-2 mb-4">
              <div className="p-2 bg-red-900/30 rounded-lg">
                <Youtube className="h-4 w-4 text-red-500" />
              </div>
              <div>
                <h4 className="text-sm font-medium text-white">Channel Videos</h4>
                <p className="text-xs text-gray-400">Video {currentVideoIndex + 1} of {channel.videos?.length || 0}</p>
              </div>
              <div className="ml-auto flex items-center gap-2">
                <button
                  onClick={() => setCurrentVideoIndex(prev => Math.max(0, prev - 1))}
                  disabled={currentVideoIndex === 0}
                  className="p-2 text-gray-400 hover:text-white disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  <ChevronLeft className="h-5 w-5" />
                </button>
                <button
                  onClick={() => {
                    if (hasMoreVideos) {
                      resetVideoState();
                      setCurrentVideoIndex(prev => prev + 1);
                    } else if (canLoadMore) {
                      handleLoadMoreVideos();
                    }
                  }}
                  disabled={!hasMoreVideos && !canLoadMore}
                  className="p-2 text-gray-400 hover:text-white disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  <ChevronRight className="h-5 w-5" />
                </button>
              </div>
            </div>
            <div className="bg-gray-900/50 rounded-lg border border-gray-800 overflow-hidden hover:border-purple-500/50 transition-all duration-200 group">
              <a
                href={`https://www.youtube.com/watch?v=${currentVideo.id}`}
                target="_blank"
                rel="noopener noreferrer"
                className="block"
              >
                <div className="relative aspect-video overflow-hidden">
                  {/* Engagement Score Overlay */}
                  <EngagementScoreOverlay
                    likeCount={currentVideo.likeCount}
                    viewCount={currentVideo.viewCount}
                    commentCount={currentVideo.commentCount}
                    publishedAt={currentVideo.publishedAt}
                    totalViews={channel.statistics.viewCount}
                    videos={channel.statistics.videoCount}
                    subscribers={channel.statistics.subscriberCount}
                    recentVideoViews={channel.videos?.map(v => v.viewCount) || []}
                  />
                  <img
                    src={currentVideo.thumbnail}
                    alt={currentVideo.title}
                    className="absolute inset-0 w-full h-full object-cover group-hover:scale-105 transition-transform duration-300"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/20 to-transparent"></div>
                  <div className="absolute bottom-0 left-0 right-0 p-4">
                    <h5 className="text-white font-medium line-clamp-2 text-sm sm:text-base group-hover:text-purple-300 transition-colors duration-200">
                      {currentVideo.title}
                    </h5>
                  </div>
                </div>
              </a>
              <div className="p-4 bg-gray-900/30">
                <div className="grid grid-cols-2 xs:grid-cols-4 gap-3 sm:gap-4 text-center xs:text-left">
                  <div className="flex items-center gap-2 justify-center sm:justify-start">
                    <Calendar className="h-4 w-4 text-gray-400" />
                    <span className="text-sm text-gray-300">
                      {new Date(currentVideo.publishedAt).toLocaleDateString()}
                    </span>
                  </div>
                  <div className="flex items-center gap-2 justify-center sm:justify-start">
                    <Eye className="h-4 w-4 text-gray-400" />
                    <span className="text-sm text-gray-300">
                      {formatNumber(currentVideo.viewCount)}
                    </span>
                  </div>
                  <div className="flex items-center gap-2 justify-center sm:justify-start">
                    <ThumbsUp className="h-4 w-4 text-gray-400" />
                    <span className="text-sm text-gray-300">
                      {formatNumber(currentVideo.likeCount)}
                    </span>
                  </div>
                  <div className="flex items-center gap-2 justify-center sm:justify-start">
                    <MessageCircle className="h-4 w-4 text-gray-400" />
                    <span className="text-sm text-gray-300">
                      {formatNumber(currentVideo.commentCount)}
                    </span>
                  </div> 
                </div>
                
                {/* Comments Section */}
                <div className="mt-4 border-t border-gray-700 pt-4">
                  {!comments.length && !isLoadingComments && !commentsError && (
                    <button
                      onClick={handleLoadComments}
                      className="w-full flex items-center justify-center gap-2 px-4 py-2 bg-gray-800 hover:bg-gray-700 text-gray-300 rounded-lg transition-colors duration-200"
                    >
                      <MessageCircle className="h-4 w-4" />
                      <span>Load Top Comments</span>
                    </button>
                  )}
                  
                  {isLoadingComments && (
                    <div className="flex items-center justify-center gap-2 text-gray-400 py-4">
                      <Loader2 className="h-5 w-5 animate-spin" />
                      <span>Loading comments...</span>
                    </div>
                  )}
                  
                  {commentsError && (
                    <div className="text-red-400 text-sm text-center py-4">
                      {commentsError}
                    </div>
                  )}
                  
                  {comments.length > 0 && (
                    <div className="space-y-4">
                      <div className="flex items-center justify-between mb-4">
                        <h6 className="text-sm font-medium text-gray-400">Top Comments</h6>
                        <span className="text-xs bg-gray-800 text-gray-400 px-2 py-1 rounded-full">
                          {comments.length} comments
                        </span>
                      </div>
                      <div className="space-y-6">
                        {comments.map((comment) => (
                          <div key={comment.id} className="bg-gray-900/30 rounded-lg p-4 border border-gray-800">
                            <div className="flex gap-3">
                              <img
                                src={comment.authorProfileImageUrl}
                                alt={comment.authorDisplayName}
                                className="w-8 h-8 rounded-full shrink-0"
                              />
                              <div className="flex-1 min-w-0">
                                <div className="flex flex-wrap items-center gap-2 mb-2">
                                  <a
                                    href={comment.authorChannelUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-sm font-medium text-gray-300 hover:text-white truncate max-w-[200px]"
                                  >
                                    {comment.authorDisplayName}
                                  </a>
                                  <span className="text-xs bg-gray-800/50 text-gray-400 px-2 py-0.5 rounded-full">
                                    {new Date(comment.publishedAt).toLocaleDateString()}
                                  </span>
                                </div>
                                <p className="text-sm text-gray-300 break-words">{decodeHtmlEntities(comment.textDisplay)}</p>
                                <div className="flex flex-wrap items-center gap-4 mt-3">
                                  <span className="text-xs bg-gray-800/50 text-gray-400 px-2 py-1 rounded-full flex items-center gap-1">
                                    <ThumbsUp className="h-3 w-3" />
                                    {comment.likeCount}
                                  </span>
                                  {comment.totalReplyCount > 0 && (
                                    <span className="text-xs bg-gray-800/50 text-gray-400 px-2 py-1 rounded-full flex items-center gap-1">
                                      <MessageCircle className="h-3 w-3" />
                                      {comment.totalReplyCount} replies
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      
                      {/* Summary Section */}
                      <div className="mt-8 pt-8 border-t border-gray-700">
                        {!summary && !isGeneratingSummary && !summaryError && (
                          <button
                            onClick={handleGenerateSummary}
                            className="w-full flex items-center justify-center gap-2 px-4 py-3 bg-purple-600/20 hover:bg-purple-600/30 text-purple-300 rounded-lg transition-colors duration-200 hover:scale-[1.02]"
                          >
                            <Sparkles className="h-4 w-4" />
                            <span>Generate AI Summary with Gemini</span>
                          </button>
                        )}
                        
                        {isGeneratingSummary && (
                          <div className="flex items-center justify-center gap-2 text-purple-400 py-4">
                            <Loader2 className="h-5 w-5 animate-spin" />
                            <span>Generating summary...</span>
                          </div>
                        )}
                        
                        {summaryError && (
                          <div className="text-red-400 text-sm text-center py-4">
                            {summaryError}
                          </div>
                        )}
                        
                        {summary && (
                          <div className="bg-purple-900/20 rounded-lg p-4 border border-purple-800/30 prose prose-invert max-w-none">
                            <div className="flex items-center gap-2 mb-3">
                              <Sparkles className="h-4 w-4 text-purple-400" />
                              <h6 className="text-sm font-medium text-purple-300">Gemini AI-Generated Summary</h6>
                            </div>
                            <div 
                              className="text-sm text-gray-300 markdown-content"
                              dangerouslySetInnerHTML={{ __html: summary }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div> 
            </div>
          </div>
        )}
      </div>
    </div>
  );
});

// Reusable Statistic Card Component
interface StatCardProps {
  icon: JSX.Element;
  label: string;
  value: string | number;
  variant?: 'primary' | 'secondary';
}

const StatCard = ({ icon, label, value, variant = 'primary' }: StatCardProps) => (
  <div className={`
    group bg-gray-900/50 p-4 rounded-lg hover:bg-gray-900/70 transition-all duration-200 
    border border-gray-800/50 hover:border-purple-500/50 
    ${variant === 'primary' ? 'bg-gradient-to-br from-gray-900/70 to-gray-800/70 shadow-md' : ''}
  `}>
    <div className="flex items-center gap-3 mb-2">
      <div className={`
        p-2 rounded-lg transition-colors duration-200 shrink-0 w-10 h-10 flex items-center justify-center
        ${variant === 'primary' 
          ? 'bg-purple-900/30 group-hover:bg-purple-900/50' 
          : 'bg-gray-800/50 group-hover:bg-gray-800'}
      `}>
        {icon}
      </div>
      <p className="text-sm text-gray-400">{label}</p>
    </div>
    <p className={`
      font-bold tracking-tight text-right
      ${variant === 'primary' ? 'text-2xl text-white' : 'text-xl text-gray-200'}
    `}>{value}</p>
  </div>
);