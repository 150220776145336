import { Mail, Target, BarChart3 } from 'lucide-react';
import { CRMProfileCard } from '../crm/CRMProfileCard';
import type { Subscriber } from '../../types';

interface CRMPreviewSectionProps {
  sampleSubscriber: Subscriber;
}

export function CRMPreviewSection({ sampleSubscriber }: CRMPreviewSectionProps) {
  return (
    <div className="bg-gradient-to-r from-purple-900/20 to-pink-900/20 py-12 sm:py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-8 sm:mb-12">
          <h2 className="text-3xl font-extrabold mb-4">
            Powerful CRM Tools
          </h2>
          <p className="mt-4 max-w-2xl mx-auto text-base sm:text-xl text-gray-400">
            Get detailed insights about your community members
          </p>
        </div>
        
        <div className="max-w-2xl mx-auto bg-black/20 backdrop-blur-sm p-4 sm:p-8 rounded-xl sm:rounded-2xl">
          <CRMProfileCard subscriber={sampleSubscriber} />
          
          <div className="mt-6 sm:mt-8 grid grid-cols-1 sm:grid-cols-3 gap-4">
            <div className="bg-gray-800/50 rounded-lg p-3 sm:p-4 border border-gray-700">
              <Mail className="h-5 w-5 text-purple-400 mb-2" />
              <p className="text-sm font-medium text-gray-300">Verified Emails</p>
              <p className="text-xs text-gray-500">Direct communication</p>
            </div>
            <div className="bg-gray-800/50 rounded-lg p-3 sm:p-4 border border-gray-700">
              <Target className="h-5 w-5 text-blue-400 mb-2" />
              <p className="text-sm font-medium text-gray-300">Interest Tracking</p>
              <p className="text-xs text-gray-500">Personalized content</p>
            </div>
            <div className="bg-gray-800/50 rounded-lg p-3 sm:p-4 border border-gray-700">
              <BarChart3 className="h-5 w-5 text-green-400 mb-2" />
              <p className="text-sm font-medium text-gray-300">Engagement Data</p>
              <p className="text-xs text-gray-500">Activity metrics</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}