// Utility functions for text formatting

/**
 * Decodes HTML entities and handles common formatting patterns in text
 * 
 * @param text - The text containing HTML entities to decode
 * @returns The decoded and formatted text
 */
export function decodeHtmlEntities(text: string): string {
  if (!text) return '';

  // Create a temporary textarea element to decode HTML entities
  const textarea = document.createElement('textarea');
  textarea.innerHTML = text;
  
  // Get decoded text
  let decoded = textarea.value;

  // Handle line breaks
  decoded = decoded.replace(/<br\s*\/?>/gi, '\n');
  
  // Remove any remaining HTML tags
  decoded = decoded.replace(/<[^>]*>/g, '');

  return decoded;
}