import { useState, useEffect } from 'react';
import { URLInput } from './URLInput';

export function StickyURLInput() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // Get the hero section height (including some padding)
      const heroSection = document.querySelector('[data-section="hero"]');
      if (!heroSection) return;
      
      const heroHeight = heroSection.getBoundingClientRect().height;
      const scrollPosition = window.scrollY;
      
      setIsVisible(scrollPosition > heroHeight);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  if (!isVisible) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-gradient-to-t from-black to-black/95 border-t border-gray-800 p-4 transform transition-all duration-300 ease-in-out z-50">
      <div className="max-w-md mx-auto">
        <URLInput onSubmit={(url) => console.log('Reserved URL:', url)} />
      </div>
    </div>
  );
}