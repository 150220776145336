import type { EngagementMetric, Follower, Task } from '../types';

// Analytics Data
export const competitorAnalysis = [
  { name: 'Your Channel', subscribers: 156000, engagement: 8.5, brandDeals: 12 },
  { name: 'Similar Creator 1', subscribers: 142000, engagement: 4.2, brandDeals: 8 },
  { name: 'Similar Creator 2', subscribers: 168000, engagement: 3.8, brandDeals: 7 },
  { name: 'Similar Creator 3', subscribers: 134000, engagement: 5.1, brandDeals: 9 }
];

export const audienceOverlap = [
  { brand: 'Travel Gear', overlap: 78, potential: 92 },
  { brand: 'Photography', overlap: 65, potential: 85 },
  { brand: 'Lifestyle', overlap: 45, potential: 72 }
];

export const brandAffinityData = [
  { category: 'Luxury Travel', affinity: 85, conversion: 12.3 },
  { category: 'Camera Gear', affinity: 72, conversion: 8.7 },
  { category: 'Adventure Gear', affinity: 68, conversion: 7.9 }
];

export const partnershipTiers = [
  {
    name: 'Premium Integration',
    price: '$8,500',
    includes: [
      'Dedicated 15-20 minute video review',
      'Instagram Story series (5 stories)',
      'Community post with exclusive discount',
      '30-day link placement in video descriptions',
      'Dedicated email to subscriber list'
    ]
  },
  {
    name: 'Standard Package',
    price: '$5,500',
    includes: [
      '5-8 minute integrated review segment',
      'Instagram Story mention (3 stories)',
      'Community post with discount code',
      '14-day link placement in descriptions'
    ]
  }
];

export const pastDeals = [
  {
    brand: 'Away Luggage',
    deal: 'Premium Integration',
    results: {
      revenue: '$52,400',
      conversion: '9.2%',
      roi: '515%'
    },
    testimonial: "Heidi's audience alignment was incredible. The conversion rates exceeded our expectations by 3x the industry average.",
    contact: "Sarah Chen, Partnerships Lead"
  },
  {
    brand: 'DJI',
    deal: 'Standard Package',
    results: {
      revenue: '$38,900',
      conversion: '7.8%',
      roi: '480%'
    },
    testimonial: "The quality of traffic and engagement from Heidi's community was outstanding. We saw a significant boost in premium drone sales.",
    contact: "Michael Torres, Influencer Marketing"
  }
];

export const offers = [
  {
    id: '1',
    brand: 'Peak Design',
    brandLogo: 'https://images.unsplash.com/photo-1551150441-3f3828204ef0?ixlib=rb-1.2.1&auto=format&fit=crop&w=200&h=200&q=80',
    title: 'Travel Backpack Bundle',
    description: 'Get 20% off the Travel Line collection including the award-winning Travel Backpack 45L',
    discount: '20% OFF',
    code: 'HEIDI20',
    link: 'https://www.peakdesign.com/collections/travel-bags',
    category: 'Travel Gear',
    expiresAt: '2024-04-30',
    featured: true,
    stats: {
      uses: 245,
      revenue: 12350,
      conversion: 8.5
    }
  },
  {
    id: '2',
    brand: 'Moment',
    brandLogo: 'https://images.unsplash.com/photo-1542038784456-1ea8e935640e?ixlib=rb-1.2.1&auto=format&fit=crop&w=200&h=200&q=80',
    title: 'Mobile Photography Kit',
    description: 'Save 15% on mobile lenses, cases, and photography accessories',
    discount: '15% OFF',
    code: 'HEIDIPHOTO15',
    link: 'https://www.shopmoment.com/mobile',
    category: 'Photography',
    expiresAt: '2024-04-15',
    featured: true,
    stats: {
      uses: 189,
      revenue: 8920,
      conversion: 6.8
    }
  },
  {
    id: '3',
    brand: 'Away',
    brandLogo: 'https://images.unsplash.com/photo-1553062407-98eeb64c6a62?ixlib=rb-1.2.1&auto=format&fit=crop&w=200&h=200&q=80',
    title: 'Luggage Set Deal',
    description: 'Exclusive $50 off any set of two or more pieces of luggage',
    discount: '$50 OFF',
    code: 'HEIDITRAVELS',
    link: 'https://www.awaytravel.com/sets',
    category: 'Travel Gear',
    expiresAt: '2024-05-15',
    featured: false,
    stats: {
      uses: 156,
      revenue: 15600,
      conversion: 7.2
    }
  }
];

export const metrics: EngagementMetric[] = [
  {
    platform: 'Total Followers',
    value: 124500,
    trend: 12.5,
    color: '#3B82F6',
  },
  {
    platform: 'Engagement Rate',
    value: 5200,
    trend: -2.3,
    color: '#10B981',
  },
  {
    platform: 'Content Reach',
    value: 89300,
    trend: 8.1,
    color: '#8B5CF6',
  },
  {
    platform: 'Revenue',
    value: 12400,
    trend: 15.2,
    color: '#F59E0B',
  },
];

export const followers: Follower[] = [
  {
    id: '1',
    name: 'Sarah Johnson',
    username: 'sarahcreates',
    avatar: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    platform: 'instagram',
    engagement: 8.5,
    lastInteraction: '2024-02-28',
    status: 'active',
  },
  {
    id: '2',
    name: 'Michael Chen',
    username: 'techmikey',
    avatar: 'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    platform: 'twitter',
    engagement: 6.2,
    lastInteraction: '2024-02-27',
    status: 'active',
  },
  {
    id: '3',
    name: 'Emma Wilson',
    username: 'emmacreative',
    avatar: 'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    platform: 'youtube',
    engagement: 12.8,
    lastInteraction: '2024-02-26',
    status: 'potential',
  },
];

export const tasks: Task[] = [
  {
    id: '1',
    title: 'Record YouTube video on productivity tips',
    dueDate: 'Tomorrow',
    priority: 'high',
    completed: false,
  },
  {
    id: '2',
    title: 'Schedule Instagram posts for the week',
    dueDate: 'Today',
    priority: 'medium',
    completed: true,
  },
  {
    id: '3',
    title: 'Respond to Twitter mentions',
    dueDate: 'Today',
    priority: 'low',
    completed: false,
  },
  {
    id: '4',
    title: 'Review analytics and adjust content strategy',
    dueDate: 'Next week',
    priority: 'high',
    completed: false,
  },
];